import { getUserData } from "@/services/dashboard.service";
import store from "../store/index";
const notificationHandler = (message, isvisible, type) => {
  return {
    message: message,
    isNotify: isvisible,
    type: type,
  };
};

export { notificationHandler };

const dateFormatter = (date, format) => {
  const moment = require("moment");
  return moment(date).format(format ? format : "DD-MM-YYYY");
};

const filter_object_generator_from_cell_data = (
  cellData,
  keyName,
  additionalKey = null
) => {
  let data;
  let fieldName;
  let hasAnyParentField = cellData?.parent_field_name ? true : false;
  let parentFieldName = cellData?.parent_field_name;
  let hasAnySubParentField = cellData?.sub_parent_field_name ? true : false;
  let subParentFieldName = cellData?.sub_parent_field_name;
  if (cellData?.parent_field_name) {
    data = {
      [cellData?.field]: {
        ...((cellData?.[additionalKey]?.is_asc ||
          cellData?.[additionalKey]?.is_desc) && {
          [additionalKey]: cellData?.[additionalKey],
        }),
        [keyName]: cellData?.[keyName],
      },
    };
    fieldName = cellData?.parent_field_name;
  } else {
    data = {
      ...(cellData?.[additionalKey] && {
        [additionalKey]: cellData?.[additionalKey],
      }),
      [keyName]: cellData?.[keyName],
    };
    fieldName = cellData?.field;
  }
  return {
    data,
    fieldName,
    parentFieldName,
    subParentFieldName,
    hasAnyParentField,
    hasAnySubParentField,
  };
};

const highlightPolygons = (polygonOptionList, uuid, fillColor, strokeColor) => {
  polygonOptionList.forEach((polygon) => {
    if (polygon.project_uuid == uuid) {
      polygon.fillColor = fillColor;
      polygon.strokeColor = strokeColor;
      polygon.strokeWeight = 4;
      polygon.zIndex = 2;
    } else {
      polygon.fillColor = "rgba(253, 180, 76, 0.37)";
      polygon.strokeColor = "rgba(253, 180, 76, 1)";
      polygon.strokeWeight = 2;
      polygon.zIndex = 1;
    }
  });
  return polygonOptionList;
};

export {
  dateFormatter,
  filter_object_generator_from_cell_data,
  highlightPolygons,
};

const userValidation = async (allowedRoles) => {
  const user = store.state.auth.userInfo;

  if (!user?.role?.alias) {
    try {
      const res = await getUserData();
      const userInfo = res?.data?.result?.data;
      const userAliasAPICall = userInfo?.role?.alias;

      if (allowedRoles.includes(userAliasAPICall) || userInfo?.is_admin === 1) {
        return true;
      } else {
        window.location.href = "/";
        return false;
      }
    } catch (error) {
      console.error("Error fetching user data", error);
      return false;
    }
  } else {
    if (allowedRoles.includes(user?.role?.alias) || user?.is_admin === 1) {
      return true;
    } else {
      window.location.href = "/";
      return false;
    }
  }
};

export { userValidation };
