<AppGlobalLoader :loaderColor="'solid'" :isFullpage="true"
    v-if="isLoadingCreateUpdate || isLoadingCreateProjectUpdate || tabisLoadingDeleteValue " />
<AppSnackBar :message="notification.message" :notificationType="notification.type" v-if="notification.isNotify">
</AppSnackBar>
<AppSnackBar :message="notificationProject.message" :notificationType="notificationProject.type"
    v-if="notificationProject.isNotify">
</AppSnackBar>
<div v-if="overViewData && !(isLoadingCreateUpdate || isLoadingCreateProjectUpdate)"
    class="overview-page-nav d-flex justify-content-between border-bottom-1-gray p-11">
    <div class="left d-flex h-66">
        <kbutton @click="gotoPrev" :fill-mode="'flat'" :icon="'k-icon k-i-arrow-chevron-left'"
            :class="'py-0 font-20 font-weight-400 h-66 me-1'"></kbutton>
        <div class="overview-avatar h-66">
            <span v-if="!tempPhotoPath"
                class="d-inline-block h-66 w-66 line-h-66px text-center radius-100 bg-primary white-color">
                {{iconUsingName(overViewData?.company?.name)}}
            </span>
            <img v-if="tempPhotoPath" :src="tempPhotoPath" />
        </div>
        <div class="overview-info d-flex flex-column justify-content-center ps-3 h-66">
            <h4 class="font-20 line-h-1_4 mb-0">{{overViewData?.company?.name}}</h4>
            <p class="font-12 line-h-1_4 mb-0">{{overViewData?.company?.code}}</p>
        </div>
        <div class="overview-tags ps-3 pt-3 h-66">
            <span v-for="(sector, index) in modifiedSector" :key="sector.id"
                :class="`d-inline-block h-22 font-12 line-h-1_2 border-1-normal p-1 radius-4 me-2 ${ sector.alias == overViewData?.company?.market_map?.alias ? 'active_market_map' : ''}`">{{sector?.name}}</span>
        </div>
    </div>
    <div class="right d-flex">
        <div>
            <h2 class="font-28 line-h-1_3 font-weight-300 d-flex align-items-center mb-0 overview-tags ps-0">
                {{formatTwoOrThreeDecimal(overViewData?.top_ribbon?.price)}}
                <span class="font-16 font-weight-500 mt-12 ms-2"
                    :class="overViewData?.company?.day>0?'green-color':overViewData?.company?.day==0?'':'red-color'">
                    <!-- stroke-width="0.583333"  -->
                    <span v-if="overViewData?.company?.day>0" class="align-text-bottom">
                        <svg width="16" height="16" viewBox="0 0 14 14" fill="#28A745" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.8125 3.9375L7 1.75M7 1.75L9.1875 3.9375M7 1.75L7 12.25" stroke="#28A745" fill="#28A745"
                            stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <span v-if="overViewData?.company?.day<0" class="align-text-bottom">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="#EB5E3F"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 17.25L12 21M12 21L8.25 17.25M12 21V3" stroke="#EB5E3F" fill="#EB5E3F"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    {{overViewData?.top_ribbon?.day}}
                    ({{overViewData?.top_ribbon?.pct_change}}%)</span>
            </h2>
            <div class="d-flex align-items-center">
                <span
                    class="d-inline-block h-22 primary-color font-12 line-h-1_2 border-1-orange p-1 radius-4 me-2">Delayed</span>
                <p class="font-12 line-h-1_4 mb-0">
                    Last updated at {{overViewData?.company?.price_time ?
                    getTimeIn12HourClock(overViewData?.company?.price_time):""}}
                    {{formatDate(overViewData?.company?.price_date,'DD/MM/YY')}}
                </p>
            </div>
        </div>
        <div class="d-flex flex-column justify-content-end ps-4">
            <p class="font-12 line-h-1_4 font-weight-400 mb-1">
                Bid:
                <b>{{overViewData?.top_ribbon?.bid}}</b>
            </p>
            <p class="font-12 line-h-1_4 font-weight-400 mb-1">
                Volume:
                <b>{{overViewData?.top_ribbon?.volume?.value ? overViewData?.top_ribbon?.volume?.value : overViewData?.top_ribbon?.volume}}</b><b
                    class="font-10">{{overViewData?.top_ribbon?.volume?.number_format}}</b>
            </p>
            <p class="font-12 line-h-1_4 font-weight-400 mb-1">
                RVol:
                <b>{{overViewData?.top_ribbon?.intraday_rvol?.value ? overViewData?.top_ribbon?.intraday_rvol?.value : overViewData?.top_ribbon?.intraday_rvol}}</b><b
                    class="font-10">{{overViewData?.top_ribbon?.intraday_rvol?.number_format}}</b>
            </p>
        </div>
        <div class="d-flex flex-column justify-content-end ps-5 pe-4">
            <p class="font-12 line-h-1_4 font-weight-400 mb-1">
                Ask:
                <b>{{overViewData?.top_ribbon?.ask}}</b>
            </p>
            <p class="font-12 line-h-1_4 font-weight-400 mb-1">
                Value:
                <b>{{overViewData?.top_ribbon?.turnover?.value ? overViewData?.top_ribbon?.turnover?.value : overViewData?.top_ribbon?.turnover}}</b><b
                    class="font-10">{{overViewData?.top_ribbon?.turnover?.number_format}}</b>
            </p>
            <p class="font-12 line-h-1_4 font-weight-400 mb-1">
                VWAP:
                <b>{{formatNumberWithoutDoller(overViewData?.top_ribbon?.vwap)}}</b>
            </p>
        </div>
    </div>
</div>
<div v-if="overViewData && !(isLoadingCreateUpdate || isLoadingCreateProjectUpdate)"
    class="overview-page-content k-overflow-y-auto max-h-100-180">
    <!-- <div class="overview-content-chart d-flex flex-wrap justify-content-between py-32 px-56"> -->
    <div class="overview-content-chart pt-32 px-56">
        <div class="row">
            <div class="col-xxl-7">
                <div class="left pe-2">
                    <h4 v-if="overViewData?.company?.company_overview" class="font-15 font-weight-700 mb-1">
                        Company Overview
                    </h4>
                    <p v-if="overViewData?.company?.company_overview" class="font-15 line-h-1_4 mb-32" >
                        {{overViewData?.company?.company_overview}}
                    </p>
                    <div class="w-660 mb-4">
                        <h6 class="font-15 font-weight-700 mb-16">Highlights</h6>
                        <div v-if="overViewData?.financial" class="row gx-3">
                            <div class="col-lg-4 mb-3">
                                <div class="overview-highlights-box border-1-gray radius-4 p-16 shadow-box">
                                    <p class="font-14 line-h-1_4 font-weight-400">Market Capitalisation</p>
                                    <p class="font-16 line-h-1_4 font-weight-700 mb-0">
                                        <span
                                            v-if="overViewData?.financial?.market_cap!='-'">
                                            {{overViewData?.financial?.market_cap.value != "" ? "$": ""}}</span>{{overViewData?.financial?.market_cap.value}}<span class="font-14"
                                            v-if="overViewData?.financial?.market_cap.number_format">{{overViewData?.financial?.market_cap.number_format}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="overview-highlights-box border-1-gray radius-4 p-16 shadow-box">
                                    <p class="font-14 line-h-1_4 font-weight-400">Shares on Issue</p>
                                    <p class="font-16 line-h-1_4 font-weight-700 mb-0">
                                        {{overViewData?.highlights?.shares_on_issue.value}}<span class="font-14"
                                        v-if="overViewData?.highlights?.shares_on_issue?.number_format">{{overViewData?.highlights?.shares_on_issue?.number_format + ' '}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="overview-highlights-box border-1-gray radius-4 p-16 shadow-box">
                                    <p class="font-14 line-h-1_4 font-weight-400">
                                        YTD Share Performance
                                    </p>
                                    <p class="font-16 line-h-1_4 font-weight-700 mb-0"
                                        :class="convertToNumber(overViewData?.highlights?.ydt_share_performance)>0 ?'green-color':'red-color'">
                                        {{overViewData?.highlights?.ydt_share_performance}}%
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="overview-highlights-box border-1-gray radius-4 p-16 shadow-box">
                                    <p class="font-14 line-h-1_4 font-weight-400">Cash</p>
                                    <p class="font-16 line-h-1_4 font-weight-700 mb-0"  >
                                        <span v-if="overViewData?.highlights?.cash!='-'">
                                            {{overViewData?.highlights?.cash?.value != "" ? "$": ""}}</span>{{overViewData?.highlights?.cash?.value}}<span class="font-14"
                                            v-if="overViewData?.highlights?.cash.number_format">{{overViewData?.highlights?.cash.number_format}}</span>
                                        <span
                                            class="font-weight-400 font-13 font-italic">{{ " " + formatDate(overViewData?.financial?.as_of_date,'DD/MM/YYYY')}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="overview-highlights-box border-1-gray radius-4 p-16 shadow-box">
                                    <p class="font-14 line-h-1_4 font-weight-400">Debt</p>
                                    <p class="font-16 line-h-1_4 font-weight-700 mb-0" >
                                        <span v-if="overViewData?.highlights?.debt!='-'">
                                            {{overViewData?.highlights?.debt.value != "" ? "$": ""}}</span>{{overViewData?.highlights?.debt.value}}<span class="font-14"
                                            v-if="overViewData?.highlights?.debt.number_format">{{overViewData?.highlights?.debt.number_format}}
                                        </span>
                                        <span
                                            class="font-weight-400 font-13 font-italic">{{" " + formatDate(overViewData?.financial?.as_of_date,'DD/MM/YYYY')}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="overview-highlights-box border-1-gray radius-4 p-16 shadow-box">
                                    <p class="font-14 line-h-1_4 font-weight-400">Enterprise Value</p>
                                    <p class="font-16 line-h-1_4 font-weight-700 mb-0" >
                                        {{overViewData?.highlights?.enterprise_value.value}}<span class="font-14"
                                            v-if="overViewData?.highlights?.enterprise_value.number_format">{{overViewData?.highlights?.enterprise_value.number_format}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-5">
                <div class="right ps-2">
                    <div v-if="isAsxAdded" class="w-690 ms-xxl-auto overview-chart-wrap">
                        <div v-if="loggedInUserData?.role?.alias == 'admin'" class="d-flex justify-content-end">
                            <kbutton @click="goToEditCompany" :class="'h-38 mb-16'" :size="'medium'" :icon="''">Edit
                                Company
                            </kbutton>
                            <DropDownButton :size="'small'" :popup-settings="popupSettings" :icon="'more-vertical'"
                                :items="companyStatus"
                                :class="'btn-inside-transparent btn-inside-p-0 h-38 ms-3 btn-focus-bg-gray'"
                                @itemclick="deleteTemplate" />
                        </div>
                        <h4 class="font-15 font-weight-700 mb-1">Price Chart</h4>
                        <iframe :src="isLive ? `https://api.miningbull.com.au/trading?symbol=${isAsxAdded}` : `https://miningbull-api.daybud.com/trading?symbol=${isAsxAdded}`"
                            class="price-chart-iframe" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="overview-tables pb-32 px-56 pb-2">
        <div class="mb-40">
            <div class="d-flex justify-content-between align-items-center mb-8">
                <p class="font-15 font-weight-700 mb-0">Projects</p>
                <kbutton v-if="loggedInUserData?.role?.alias == 'admin'" @click="newProject" :theme-color="'base'" :class="''">New Project</kbutton>
            </div>
            <Grid :class="loggedInUserData?.role?.alias == 'admin'? ProjectTableData.length > 10 ? 'show-scrollbar projects-table-wrap' : 'projects-table-wrap': ProjectTableData.length > 5 ? 'show-scrollbar user-projects-table' : 'user-projects-table'" ref="grid" :data-items="ProjectTableData"
                :columns="columns">
                <template v-slot:nameCell="{props}">
                    <td>
                        <p @click="projectView($event,props)"
                            class="font-14 line-h-1_4 base-color mb-0 k-cursor-pointer">
                            {{props.dataItem.project_name }}
                        </p>
                    </td>
                </template>
                <template v-slot:cell_status="{props}">
                    <td>
                        <p  @click="projectView($event,props)"
                            class="font-14 line-h-1_4 base-color mb-0 k-cursor-pointer">
                            {{ props.dataItem.status }}
                        </p>
                    </td>
                </template>
                <template v-slot:cell_current_ownership="{props}">
                    <td>
                        <p  @click="projectView($event,props)"
                            class="font-14 line-h-1_4 base-color mb-0 k-cursor-pointer">
                            {{ props.dataItem.current_ownership }}
                        </p>
                    </td>
                </template>
                <template v-slot:mainCommodities="{props}">
                    
                    <td>
                        <span v-if="props.dataItem.stage == 'Exploration'"
                        v-for="(maincomo,index) in props?.dataItem?.main_commodities">
                        {{ maincomo.name }}{{ index < props?.dataItem?.main_commodities?.length - 1 ? ', ' : '' }}
                    </span>
                    <span v-if="props.dataItem.stage == 'Exploration'">
                        {{props?.dataItem?.main_commodities?.length && props?.dataItem?.additional_main_commodities ? `, ${props?.dataItem?.additional_main_commodities}` : props?.dataItem?.additional_main_commodities}}
                    </span>
                    <span v-if="props.dataItem.stage != 'Exploration'">{{props.dataItem.project_name == "Barrambie" && props.dataItem.main_commodities == "Vanadium" ? "Titanium" : props.dataItem.main_commodities }}
                    </span>
                </td>
            </template>
            <template v-slot:cell_other_commodities="{props}">
                <td>
                    <p  @click="projectView($event,props)"
                        class="font-14 line-h-1_4 base-color mb-0 k-cursor-pointer">
                        {{ props.dataItem.other_commodities }}
                    </p>
                </td>
            </template>
            <!-- <template v-slot:otherCommodities="{props}">
                <td>
                        <span v-if="props.dataItem.other_commodities.length"
                            v-for="othercomo in props.dataItem.other_commodities">{{othercomo.name }}</span>
                    </td>
                </template> -->
                <template v-slot:countryCell="{props}">
                    
                    <td>
                        <span style="min-height: 42px; display: inline-flex; align-items: center;">{{props.dataItem.country?.country_name ?
                            props.dataItem.country?.country_name : props.dataItem.country
                        }}</span>
                    </td>
                </template>
                <template v-slot:stateCell="{props}">
                    
                    <td>
                        <span>{{props.dataItem.state?.state_name ?
                            props.dataItem.state?.state_name : props.dataItem.state }}</span>
                        </td>
                    </template>
                    <template v-slot:cell_stage="{props}">
                        
                        <td>
                            <p @click="projectView($event,props)"
                                class="font-14 line-h-1_4 base-color mb-0 k-cursor-pointer">
                                {{props.dataItem.stage }}
                            </p>
                        </td>
                    </template>

                <template v-slot:actionCell="{props}">
                    <td>
                        <DropDownButton :size="'small'" :popup-settings="popupSettings" :icon="'more-vertical'"
                            :items="passStatuses"
                            :class="'btn-inside-transparent btn-inside-p-0 btn-focus-bg-gray'"
                            @itemclick="deleteProject($event,props)" />
                    </td>
                </template>
                <template v-slot:rankingCell="{props}">
                    <td :class="'rank-box-input'">
                        <span class="input-value-box" @click="rowClick(props)"
                            v-if="!props.dataItem.inEdit">{{props.dataItem.rank}}</span>
                        <div class="d-flex align-items-center py---2" v-if="props.dataItem.inEdit">
                            <numerictextbox v-if="props.dataItem.inEdit" :class="'mb-0'" ref="OptionCodeInput"
                                placeholder="Enter Rank" aria-required="true" type="number"
                                v-model="rankingLocalData" />
                            <kbutton v-if="props.dataItem.inEdit" @click="applyRanking(props)" :size="'small'"
                                :fill-mode="'flat'" :icon="'check'"></kbutton>
                            <kbutton v-if="props.dataItem.inEdit" @click="close(props)" :size="'small'"
                                :fill-mode="'flat'" :icon="'close'"></kbutton>
                        </div>
                    </td>
                </template>
            </Grid>
        </div>

        <div class="row">
            <div class="col-xl-7">
                <div class="row">
                    <div class="col-md-6 pe-5">
                        <div class="d-flex justify-content-between mb-2">
                            <p class="font-15 font-weight-700 mb-0 me-2">Activity</p>
                            <div class="d-flex">
                                <span
                                    class="d-inline-block h-22 primary-color font-12 line-h-1_2 border-1-orange p-1 radius-4 me-2">Delayed</span>
                                <p class="font-13 mb-0">
                                    Last updated at {{overViewData?.company?.price_time ?
                                    getTimeIn12HourClock(overViewData?.company?.price_time):""}}
                                    {{formatDate(overViewData?.company?.price_date,'DD/MM/YY')}}
                                </p>
                            </div>
                        </div>
                        <div class="border-1-gray table-bg-gray radius-4 mb-3">
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Price</p>
                                <p class="font-14 mb-0">
                                    {{formatTwoOrThreeDecimal(overViewData?.activity?.price)}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Change</p>
                                <span class="font-14 font-weight-400 mt-0 ms-2"
                                    :class="overViewData?.company?.day>0?'green-color':overViewData?.company?.day==0?'':'red-color'">
                                    <span v-if="overViewData?.company?.day>0" class="align-text-bottom">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="#28A745"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.8125 3.9375L7 1.75M7 1.75L9.1875 3.9375M7 1.75L7 12.25"
                                                stroke="#28A745" fill="#28A745" stroke-width="1" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <span v-if="overViewData?.company?.day<0" class="align-text-bottom">
                                        <svg class="mb-1" width="14" height="14" viewBox="0 0 24 24" fill="#EB5E3F"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.75 17.25L12 21M12 21L8.25 17.25M12 21V3" stroke="#EB5E3F" fill="#EB5E3F"
                                            stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    {{overViewData?.company?.day}}
                                    ({{overViewData?.company?.pct_change}}%)</span>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 line-h-1_4 mb-0">Bid/Ask</p>
                                <p class="font-14 line-h-1_4 mb-0">
                                    {{formatTwoOrThreeDecimal(overViewData?.top_ribbon?.bid) + " / " + formatTwoOrThreeDecimal(overViewData?.top_ribbon?.ask)}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Volume</p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.activity?.volume}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Value</p>
                                <p class="font-14 mb-0">
                                    ${{overViewData?.activity?.turnover}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">VWAP</p>
                                <p class="font-14 mb-0">
                                    {{formatNumberWithoutDoller(overViewData?.activity?.vwap)}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Daily Range</p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.activity?.range}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Daily RVol</p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.activity?.intraday_rvol}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Average Volume</p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.activity?.average_volume}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Average Value</p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.activity?.average_value}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Weekly RVol</p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.activity?.weekly_rvol}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">52 Week Range</p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.activity?.week_low}} - {{overViewData?.activity?.week_high}}
                                </p>
                            </div>
                            <!-- <div class="d-flex justify-content-between py-10 px-13">
                                <p class="font-14 mb-0">52 Week Low</p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.activity?.week_low}}
                                </p>
                            </div> -->
                        </div>
                    </div>
                    <div v-if="overViewData?.financial" class="col-md-6 mb-3 pe-5 ps-5">
                        <p class="font-15 font-weight-700 mb-2">Financial Information</p>
                        <div class="border-1-gray table-bg-gray radius-4 mb-40">
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">
                                    Shares on Issue
                                </p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.financial?.shares_on_issue?.value}}<span
                                        v-if="overViewData?.financial?.shares_on_issue.number_format">{{overViewData?.financial?.shares_on_issue.number_format}}</span>
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Market Capitalisation</p>
                                <p class="font-14 mb-0">
                                    <span v-if="overViewData?.financial?.market_cap!='-'">
                                        {{overViewData?.financial?.market_cap?.value != "" ? "$": ""}}</span>{{overViewData?.financial?.market_cap?.value}}<span
                                        v-if="overViewData?.financial?.market_cap?.number_format">{{overViewData?.financial?.market_cap?.number_format}}</span>
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">
                                    Cash
                                    {{formatDate(overViewData?.financial?.as_of_date,'DD/MM/YY')}}
                                </p>
                                <p class="font-14 mb-0" >
                                    <span v-if="overViewData?.financial?.cash!='-'">
                                        {{overViewData?.financial?.cash.value != "" ? "$": ""}}</span>{{overViewData?.financial?.cash.value}}<span
                                        v-if="overViewData?.financial?.cash?.number_format">{{overViewData?.financial?.cash?.number_format}}</span>
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">
                                    Debt
                                    {{formatDate(overViewData?.financial?.as_of_date,'DD/MM/YY')}}
                                </p>
                                <p class="font-14 mb-0" >
                                    <span v-if="overViewData?.financial?.debt!='-'">
                                        {{overViewData?.financial?.debt.value != "" ? "$": ""}}</span>{{overViewData?.financial?.debt.value}}<span
                                        v-if="overViewData?.financial?.debt?.number_format">{{overViewData?.financial?.debt?.number_format}}</span>
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">
                                    Cash/Debt Source
                                </p>
                                <p class="font-14 mb-0" >
                                    {{overViewData?.company?.method?.name == 'Quarterly' ? 'Appendix 5B' : 'Financial Statement'}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Net Cash</p>
                                <p class="font-14 mb-0" >
                                    <span v-if="overViewData?.financial?.net_cash!='-'">
                                        </span>{{overViewData?.financial?.net_cash}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Net Cash %</p>
                                <p class="font-14 mb-0" >
                                    {{overViewData?.financial?.net_cash_percentage >=
                                    0 && overViewData?.financial?.net_cash_percentage != "" ?overViewData?.financial?.net_cash_percentage+"%":overViewData?.financial?.net_cash_percentage}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13">
                                <p class="font-14 mb-0">Enterprise Value</p>
                                <p class="font-14 mb-0" >
                                    {{overViewData?.financial?.enterprise_value.value}}<span
                                        v-if="overViewData?.financial?.enterprise_value?.number_format">{{overViewData?.financial?.enterprise_value?.number_format}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <p class="font-15 font-weight-700 mb-0 me-1">Sectors</p>
                            <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'" :open="tooltipOpen">
                                <span class="k-icon k-font-icon k-i-info" title="a" @mouseenter="showTooltip" @mouseleave="hideTooltip"></span>
                                <template v-slot:myTooltipTemplate="{ props }">
                                    <div class="tooltip-content">The commodity/s that a company is primarily engaged <br> in exploring, developing or extracting.</div>
                                </template>
                            </Tooltip>
                        </div>
                        <div class="border-1-gray table-bg-gray radius-4">
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">
                                    Primary Sector
                                </p>
                                <p class="font-14 mb-0">
                                    {{overViewData?.sectors?.primary_sector?.name}}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between py-10 px-13 border-bottom-1-gray">
                                <p class="font-14 mb-0">Other Sectors</p>
                                <p class="font-14 mb-0" v-if="otherSectors?.length">
                                    <span v-for="(sector, index) in otherSectors">{{otherSectors.length == 1 ? sector?.name : index == otherSectors.length - 1 ? sector?.name : `${sector?.name}, `}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-5 mb-3 ps-5 table-text-center">
                <p class="font-15 font-weight-700 mb-2">Share Performance</p>
                <div class="custom-table ct-w-20 mb-40">
                    <div class="custom-table-row">
                        <div class="custom-table-col min-w-100 max-w-100"></div>
                        <div class="custom-table-col">
                            <p class="mb-0">Day</p>
                        </div>
                        <div class="custom-table-col">
                            <p class="mb-0">Week</p>
                        </div>
                        <div class="custom-table-col">
                            <p class="mb-0">Month</p>
                        </div>
                        <div class="custom-table-col">
                            <p class="mb-0">YTD</p>
                        </div>
                        <div class="custom-table-col">
                            <p class="mb-0">Year</p>
                        </div>
                    </div>
                    <div class="custom-table-row">
                        <div class="custom-table-col min-w-100 max-w-100">
                            {{overViewData?.company?.code}}
                        </div>
                        <div class="custom-table-col">
                            <p class="mb-0"
                                :class="convertToNumber(overViewData?.share_performance?.day)>0?'green-color':overViewData?.share_performance?.day!=0?'red-color':''">
                                <!-- <span v-if="overViewData?.share_performance?.day>0" class="ms-2 align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#28A745"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.8125 3.9375L7 1.75M7 1.75L9.1875 3.9375M7 1.75L7 12.25"
                                            stroke="#28A745" stroke-width="1" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span v-if="overViewData?.share_performance?.day<0" class="align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 24 24" fill="#EB5E3F"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.75 17.25L12 21M12 21L8.25 17.25M12 21V3" stroke="#EB5E3F"
                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span> -->
                                {{overViewData?.share_performance?.day}}{{overViewData?.share_performance?.day != '' ? '%' : ''}}
                            </p>
                        </div>
                        <div class="custom-table-col">
                            <p class="mb-0"
                                :class="convertToNumber(overViewData?.share_performance?.week)>0?'green-color':overViewData?.share_performance?.week==0?'':'red-color'">
                                <!-- <span v-if="overViewData?.share_performance?.week>0" class="ms-2 align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#28A745"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.8125 3.9375L7 1.75M7 1.75L9.1875 3.9375M7 1.75L7 12.25"
                                            stroke="#28A745" fill="#28A745" stroke-width="1" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span v-if="overViewData?.share_performance?.week<0" class="align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 24 24" fill="#EB5E3F"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.75 17.25L12 21M12 21L8.25 17.25M12 21V3" stroke="#EB5E3F" fill="#EB5E3F"
                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span> -->
                                {{overViewData?.share_performance?.week}}{{overViewData?.share_performance?.week != '' ? '%' : ''}}
                            </p>
                        </div>
                        <div class="custom-table-col"
                            :class="convertToNumber(overViewData?.share_performance?.month)>0?'green-color':overViewData?.share_performance?.month==0?'':'red-color'">
                            <p class="mb-0">
                                <!-- <span v-if="overViewData?.share_performance?.month>0" class="ms-2 align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#28A745"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.8125 3.9375L7 1.75M7 1.75L9.1875 3.9375M7 1.75L7 12.25"
                                            stroke="#28A745" fill="#28A745" stroke-width="1" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span v-if="overViewData?.share_performance?.month<0" class="align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 24 24" fill="#EB5E3F"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.75 17.25L12 21M12 21L8.25 17.25M12 21V3" stroke="#EB5E3F" fill="#EB5E3F"
                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span> -->
                                {{overViewData?.share_performance?.month}}{{overViewData?.share_performance?.month != '' ? '%' : ''}}
                            </p>
                        </div>
                        <div class="custom-table-col"
                            :class="convertToNumber(overViewData?.share_performance?.ytd)>0?'green-color':overViewData?.share_performance?.ytd==0?'':'red-color'">
                            <p class="mb-0">
                                <!-- <span v-if="overViewData?.share_performance?.ytd>=0" class="ms-2 align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#28A745"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.8125 3.9375L7 1.75M7 1.75L9.1875 3.9375M7 1.75L7 12.25"
                                            stroke="#28A745" fill="#28A745" stroke-width="1" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span v-if="overViewData?.share_performance?.ytd<0" class="align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 24 24" fill="#EB5E3F"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.75 17.25L12 21M12 21L8.25 17.25M12 21V3" stroke="#EB5E3F" fill="#EB5E3F"
                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span> -->
                                {{overViewData?.share_performance?.ytd}}{{overViewData?.share_performance?.ytd != '' ? '%' : ''}}
                            </p>
                        </div>
                        <div class="custom-table-col"
                            :class="convertToNumber(overViewData?.share_performance?.year)>0?'green-color':overViewData?.share_performance?.year==0?'':'red-color'">
                            <p class="mb-0">
                                <!-- <span v-if="overViewData?.share_performance?.year>0" class="ms-2 align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="#28A745"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.8125 3.9375L7 1.75M7 1.75L9.1875 3.9375M7 1.75L7 12.25"
                                            stroke="#28A745" fill="#28A745" stroke-width="1" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span v-if="overViewData?.share_performance?.year<0" class="align-text-bottom">
                                    <svg width="14" height="14" viewBox="0 0 24 24" fill="#EB5E3F"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.75 17.25L12 21M12 21L8.25 17.25M12 21V3" stroke="#EB5E3F" fill="#EB5E3F"
                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span> -->
                                {{overViewData?.share_performance?.year}}{{overViewData?.share_performance?.year != '' ? '%' : ''}}
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="loggedInUserData?.is_admin == 1" class="mb-40">
                    <p class="font-15 font-weight-700 mb-2">Company Options</p>
                    <Grid :class="'company-options-table-grid'" ref="grid" :data-items="overViewData?.company_options"
                        :columns="columnsCompanyOption">
                        <template v-slot:expire_cell="{props}">
                            <td>
                                {{formatDate(props.dataItem.expire_date , false)}}
                            </td>
                        </template>
                        <template v-slot:premium_cell="{props}">
                            <td>
                                {{props.dataItem.premium_percentage}}%
                            </td>
                        </template>
                        <template v-slot:price_cell="{props}">
                            <td>
                                {{formatTwoOrThreeDecimal(props.dataItem.company_option_price)}}
                            </td>
                        </template>
                    </Grid>
                </div>

                <div>
                    <p class="font-15 font-weight-700 mb-2 d-inline-block">Previous Company Names</p><span class="font-14 font-italic"> (since 01/01/2018)</span>
                    <Grid :class="'company-options-table-grid'" ref="grid"
                        :data-items="overViewData?.company_Previous_names" :columns="columnsCompanyPreviousNames">
                        <template v-slot:expire_cell="{props}">
                            <td>
                                {{formatDate(props.dataItem.previous_company_date_of_change ,
                                false)}}
                            </td>
                        </template>
                    </Grid>
                </div>
            </div>
        </div>
    </div>
</div>

<deleteCompanyModal></deleteCompanyModal>

<deleteProject :isReload="true" :deleteProjectId="deleteProjectId"></deleteProject>