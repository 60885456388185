import companyCreateEditComponent from "../components/companyComponents/companyCreateEditComponent/companyCreateEditComponent.vue";
import companyOverview from "../components/companyComponents/companyOverview/companyOverview.vue";
import newProject from "../components/companyComponents/newProjectComponents/newProjectCreateUpdate/newProjectCreateUpdate.vue";
import projectView from "../components/companyComponents/newProjectComponents/projectView/projectView.vue";
import { userValidation } from "@/helpers/utility";

const companyRoutes = [
  {
    beforeEnter: () => {
      userValidation(["admin"]);
    },
    path: "/createCompany",
    name: "createCompany",
    component: companyCreateEditComponent,
    meta: { requiresAuth: true },
  },
  {
    beforeEnter: () => {
      userValidation(["admin"]);
    },
    path: "/editCompany/:id",
    name: "editCompany",
    component: companyCreateEditComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/companyOverview/:id",
    name: "companyOverview",
    component: companyOverview,
    meta: { requiresAuth: true },
  },
  {
    beforeEnter: () => {
      userValidation(["admin"]);
    },
    path: "/companyOverview/:id/newProject",
    name: "newProject",
    component: newProject,
    meta: { requiresAuth: true },
  },
  {
    beforeEnter: () => {
      userValidation(["admin"]);
    },
    path: "/companyOverview/:id/editProject/:projectId",
    name: "editProject",
    component: newProject,
    meta: { requiresAuth: true },
  },
  {
    path: "/companyOverview/:id/view/:projectId",
    name: "projectView",
    component: projectView,
    meta: { requiresAuth: true },
  },
];

export default companyRoutes;
