<section class="login-page-wrap">
    <div class="row m-0 h-100">
        <div class="col-md-6 p-0">
            <div class="login-logo-part">
                <img src="../../../assets/images/logo.png" alt="logo" />
            </div>
        </div>

        <div class="col-md-6 p-0">
            <div class="login-form-wrap">
                <div class="login-inner">
                    <div class="title-block">
                        <h3 class="text-center">Login</h3>
                        <p>Continue to MiningBull</p>
                    </div>
                    <form @submit.prevent="goToClubDashboard">
                        <div class="k-form-field">
                            <label>Email Address</label>
                            <k-input :placeholder="'Email Address'" v-model="model.email" />
                        </div>
                        
                        <div class="k-form-field view-pass-icon with-label">
                            <div class="d-flex justify-content-between align-items-center">
                                <label>Password</label>
                                <!-- <a href="#" class="font-13 text-decoration-underline">Forgot Password?</a> -->
                            </div>
                            <k-input :type="passIcon ? 'text' : 'password'" :placeholder="'Password'"
                            v-model="model.password" />
                            <kbutton type="button" @click="showPassHandler" :class="'view-pass p-0 bg-transparent border-0'">
                                <img src="../../../assets/images/icon/preview.svg" alt="View Password" class="" />
                            </kbutton>
                        </div>
                        
                        <span v-if="errorMsg!=null && (!isTrial || isTrial == null)" class="red-color">{{errorMsg}}</span>
                        <span v-else class="red-color">Your Trial Membership has expired <b class="cursor-pointer" @click="goToAccPage">Click here</b> to upgrade your membership.</span>
                        <kbutton type="submit" :theme-color="'primary'" :class="'w-100 mt-12'">
                            <AppGlobalLoader :loaderColor="'solid'" v-if="isLoading" />
                            {{isLoading? "":"Login"}}
                        </kbutton>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>