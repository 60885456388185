import { userValidation } from "@/helpers/utility";
import googleMapsTenements from "@/components/companyComponents/newProjectComponents/newProjectCreateUpdate/googleMapsTenements.vue";

const tenementsRoutes = [
  {
    beforeEnter: () => {
      userValidation(["admin", "company"]);
    },
    path: "/interactivemaps",
    name: "interactivemaps",
    component: googleMapsTenements,
    meta: { requiresAuth: true },
  },
  {
    beforeEnter: () => {
      userValidation(["admin", "company"]);
    },
    path: "/project/:id/view/:projectId/:placeMark",
    name: "projectviewmap",
    component: googleMapsTenements,
    meta: { requiresAuth: true },
  },
];

export default tenementsRoutes;
