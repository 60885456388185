<AppGlobalLoader :loaderColor="'solid'" :isFullpage="true" v-if="isLoadingCreateProjectUpdate" />
<div v-if="!isLoadingCreateProjectUpdate">
  <div class="new-project-header-inner add-company-header-inner d-flex justify-content-between">
    <kbutton @click="back" :fill-mode="'flat'" :icon="'k-icon k-i-arrow-chevron-left'"
      :class="'py-0 font-20 font-weight-400'">{{buttonName=="Create"?"New Project":"Edit Project"}}</kbutton>
    <div>
      <kbutton @click="back" :fill-mode="'flat'" :class="'me-2'">Cancel</kbutton>
      <kbutton @click="toggleVisibleDateDialog" :theme-color="'primary'" :class="''">{{buttonName}}</kbutton>
    </div>
  </div>

  <div class="new-project-body-wrap py-32 px-56">
    <div class="row">
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Name</label>
          <k-input @keyup="error.project_name=''" :class="'h-38'" :placeholder="'-'" v-model="model.project_name" />
          <span class="error-msg">{{error?.project_name}}</span>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Stage</label>
          <dropdownlist :class="'w-100 mb-24'" :style="{  }" :value="defaultItemStage"
            :default-item="{id: 0 ,stage_name: 'Please select..'}" :data-items="itemsStage" :text-field="'stage_name'"
            @filterchange="filterChangeStage" :data-item-key="'id'" :filterable="true" @change="handleStageChange">
          </dropdownlist>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Status</label>
          <dropdownlist :class="'w-100 mb-24'" :style="{  }" :value="defaultItemStatus"
            :default-item="{id: 0, status_name:'Please select..'}" :data-items="itemsStatus" :text-field="'status_name'"
            @filterchange="filterChangeStatus" :data-item-key="'id'" :filterable="true" @change="handleStatus">
          </dropdownlist>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Primary Commodities</label>
          <dropdownlist v-if="defaultItemStage?.stage_name != 'Exploration'" :class="'w-100 mb-24'" :style="{  }"
            :value="defaultItemMainCommodities" :default-item="{id: 0, name:'Please select..',alias:'ps'}"
            :data-items="itemsMainCommodities" :text-field="'name'" @filterchange="filterChangeMainCommodities"
            :data-item-key="'id'" :filterable="true" @change="handleMainCommodities">
          </dropdownlist>

          <multiselect v-if="defaultItemStage?.stage_name == 'Exploration'" :class="'min-h-38 py-0 custom-multisilect'"
            :style="{  }" :data-items="itemsMainCommodities" :value="defaultItemMainCommodities" placeholder="Select"
            @change="mainComoditiesOnchange" :filterable="true" @filterchange="filterChangeMainCommodities"
            :text-field="'name'" :autoClose="true" :data-item-key="'id'">
          </multiselect>
          <span class="error-msg">{{error?.commodity}}</span>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Additional Primary Commodities</label>
          <k-input :class="'h-38'" :placeholder="'-'" v-model="model.additional_main_commodities" />
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Other Commodities</label>
          <k-input :class="'h-38'" :placeholder="'-'" v-model="model.other_commodities" />
        </div>
      </div>
      <div v-if="islithiumType" class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Lithium Type</label>
          <dropdownlist :class="'w-100 mb-24'" :style="{  }" :value="defaultItemLithiumType"
            :data-items="itemsLithiumType" :text-field="'type'" @filterchange="filterChangeLithiumType"
            :data-item-key="'id'" :filterable="true" @change="handleLithiumType">
          </dropdownlist>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Country</label>

          <dropdownlist :popup-settings="popupSettings" :class="'w-100 mb-24'" :style="{  }" :value="defaultItemCountry"
            :default-item="{id: 0,country_code: 'None', country_name:'Please select..'}" :data-items="itemsCountry"
            :text-field="'country_name'" @filterchange="filterChangeCountry" :data-item-key="'country_code'"
            :filterable="true" @change="handleCountry">
          </dropdownlist>
        </div>
      </div>
      <div v-if="defaultItemCountry?.country_name =='Australia' ||
            defaultItemCountry?.country_name =='United States' || defaultItemCountry?.country_name =='Canada'"
        class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">State</label>
          <dropdownlist :class="'w-100 mb-24'" :style="{  }"
            :default-item="{id: 0,country_code: 'None', state_name:'Please select..'}" :value="defaultItemStates"
            :data-items="itemStates" :text-field="'state_name'" @filterchange="filterChangeState" :data-item-key="'id'"
            :filterable="true" @change="handleState">
          </dropdownlist>
          <span class="error-msg">{{error?.state}}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Current Ownership</label>
          <div class="d-flex">
            <k-input @keyup="error.current_ownership=''" :class="'h-38 input-appearance-none border-right-0 mr--1'"
              @input="validateOwnerInput('current_ownership')" :placeholder="'-'" :min="'0'" :max="'100'"
              v-model="model.current_ownership" />
            <span class="percentage-icon-right h-38 border-1-default border-left-0 radius-0-4-4-0">
              <percentageIconVue />
            </span>
          </div>
          <span class="error-msg">{{error?.current_ownership}}</span>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Future Ownership</label>
          <div class="d-flex">
            <k-input @keyup="error.ultimate_ownership=''" @input="validateOwnerInput('ultimate_ownership')"
              :class="'h-38 input-appearance-none border-right-0 mr--1'" :placeholder="'-'" :min="'0'" :max="'100'"
              v-model="model.ultimate_ownership" />
            <span class="percentage-icon-right h-38 border-1-default border-left-0 radius-0-4-4-0">
              <percentageIconVue />
            </span>
          </div>
          <span class="error-msg">{{error?.ultimate_ownership}}</span>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Ownership Classification</label>
          <div class="d-flex">
            <multiselect :class="'min-h-38 py-0 custom-multisilect'" :style="{  }"
              :data-items="itemsOwnershipClassificationShow" :value="itemsOwnershipClassificationValue"
              placeholder="Select" @change="ownershipClassificationOnchange" :filterable="true"
              @filterchange="filterChangeOwnershipClassification" :text-field="'label'" :autoClose="true"
              :data-item-key="'id'">
            </multiselect>
          </div>
          <span class="error-msg">{{error?.ultimate_ownership}}</span>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Last updated date</label>
          <div class="d-flex">
            <datePicker :format="'dd-MM-yyyy'" :class="'h-38 w-100 mb-24'" :style="{ width: '230px' , minWidth: '18%' }"
              placeholder="DD-MM-YYYY" :value="model.last_updated_date ? new Date(model.last_updated_date) : null"
              @change="onLastUpdatedDateChange($event)">
            </datePicker>
          </div>
          <span class="error-msg">{{error?.last_updated_date}}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="row">
          <div class="col-12 mb-24">
            <div class="postion-relative">
              <label class="font-14 line-h-1_4 w-100 mb-8">Ownership Note</label>
              <textarea @keyup="error.ownership_note=''" class="w-100 min-h-80" id="w3review" name="w3review"
                v-model="model.ownership_note" rows="4"></textarea>
              <i class="font-12">Recommended Character Limit: 100 characters
                ({{texareaLengthCounter(100,model?.ownership_note)}} Remaining)
              </i>
              <span class="error-msg position-relative d-block">{{error?.ownership_note}}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="postion-relative mb-24">
              <label class="font-14 line-h-1_4 w-100 mb-8">Project Summary</label>
              <textarea @keyup="error.project_summary=''" class="w-100 min-h-80" id="w3review" name="w3review"
                v-model="model.project_summary" rows="4"></textarea>
              <!-- <i class="font-12">Maximum Characters: 500 characters
                                ({{texareaLengthCounter(500,model?.project_summary)}} Remaining)
                            </i> -->
              <span class="error-msg position-relative d-block">{{error?.project_summary}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-8 mb-24">
            <div class="postion-relative">
              <label class="font-14 line-h-1_4 w-100 mb-8">Upload Project Map</label>
              <div v-if="!isLoadingCreateProjectUpdate" class="d-flex justify-content-between bg-gray-200 p-9">
                <upload :class="'w-100'" :restrictions="{allowedExtensions: [ '.jpg', '.png' ]}"
                  :save-headers="{'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'}"
                  :default-files="imageData" :batch="false" :multiple="false" :with-credentials="false"
                  :save-url="imageUploadUrl" @statuschange="onStatusChange" @remove="onRemove" />
              </div>
            </div>
          </div>
          <div class="col-2 mb-24">
            <div class="postion-relative">
              <span><label class="font-14 line-h-1_4 w-100 mb-8">Date Published</label></span>
              <datePicker :format="'dd-MM-yyyy'" :class="'h-38 w-100 mb-24'"
                :style="{ width: '230px' , minWidth: '18%' }" placeholder="DD-MM-YYYY"
                :value="model.image_date_published ? new Date(model.image_date_published) : null"
                @change="onDateChange($event)">
              </datePicker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8" v-if="defaultItemCountry?.country_name =='Australia'">
        <label class="font-14 line-h-1_4 w-100 mb-8">Tenement</label>
        <kbutton @click="toggleEditTenements" :class="'mb-24'">Edit Tenements</kbutton>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <label class="font-14 line-h-1_4 w-100 mb-8">Placemark</label>
        <kbutton @click="toggleEditPlacment" :class="'mb-24'">Edit Placemark</kbutton>
      </div>
    </div>

    <div
      v-if="(defaultItemStage?.stage_name != 'Exploration' && defaultItemMainCommodities?.alias) && (defaultItemMainCommodities?.alias =='kaolin' || defaultItemMainCommodities?.alias =='potash')">
      <div class="col-8 mb-24">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Mineral Resource</label>
          <div v-if="!isLoadingCreateProjectUpdate" class="d-flex justify-content-between bg-gray-200 p-9">
            <upload :class="'w-100'" :restrictions="{allowedExtensions: [ '.jpg', '.png' ]}"
              :save-headers="{'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'}"
              :default-files="mineralImageData" :batch="false" :multiple="false" :with-credentials="false"
              :save-url="mineralImageUploadUrl" @statuschange="handleMineralImageStatus"
              @remove="onMineralImageRemove" />
          </div>
        </div>
        <label class="font-14 line-h-1_4 w-100 mb-8 mt-4">Width</label>
        <div class="d-flex">
          <numerictextbox :step="0" class="w-50 mr--3" placeholder="Mineral Resource table image size"
            v-model="model.mineral_image_size" />
          <span class="percentage-icon-right h-38 border-1-default border-left-0 radius-0-4-4-0">
            <percentageIconVue />
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="(defaultItemStage?.stage_name != 'Exploration' && defaultItemMainCommodities?.alias) && (defaultItemMainCommodities?.alias =='kaolin' || defaultItemMainCommodities?.alias =='potash')">
      <div class="col-8 mb-24">
        <div class="postion-relative">
          <label class="font-14 line-h-1_4 w-100 mb-8">Ore Reserve</label>
          <div v-if="!isLoadingCreateProjectUpdate" class="d-flex justify-content-between bg-gray-200 p-9">
            <upload :class="'w-100'" :restrictions="{allowedExtensions: [ '.jpg', '.png' ]}"
              :save-headers="{'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'}"
              :default-files="oreImageData" :batch="false" :multiple="false" :with-credentials="false"
              :save-url="oreImageUploadUrl" @statuschange="handleOreImageStatus" @remove="onOreImageRemove" />
          </div>
        </div>
        <label class="font-14 line-h-1_4 w-100 mb-8 mt-4">Width</label>
        <div class="d-flex">
          <numerictextbox :step="0" class="w-50" placeholder="Ore Reserve table image size"
            v-model="model.ore_image_size" />
          <span class="percentage-icon-right h-38 border-1-default border-left-0 radius-0-4-4-0">
            <percentageIconVue />
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="defaultItemStage?.stage_name != 'Exploration' && defaultItemMainCommodities?.alias!='kaolin' && defaultItemMainCommodities?.alias!='potash' && defaultItemMainCommodities?.alias"
      class="col-md-1 drop-max-w-83">
      <label class="font-14 line-h-1_4 w-100 mb-8">Deposit Type</label>
      <dropdownlist :class="'w-100 mb-24'" :style="{  }" :value="model.deposit_type" :data-items="depositTypes"
        :text-field="'name'" :data-item-key="'id'" :filterable="false" @change="handleDepositType">
      </dropdownlist>
    </div>


    <div v-if="model.deposit_type.name == 'Single'" class="">
      <div
        v-if="defaultItemStage?.stage_name != 'Exploration' && defaultItemMainCommodities?.alias!='kaolin' && defaultItemMainCommodities?.alias!='potash' && defaultItemMainCommodities?.alias"
        class="row">
        <AppGlobalLoader :class="'text-center'" :loaderColor="'solid'"
          v-if="!localTableResource?.column_mineral?.length && !localTableResource?.mineral_resource?.length" />

        <checkbox v-if="isCheckBoxVisible" :value="isMineralResourceTableDisable"
          @change="onChnageisMineralResourceTableDisable" :label="'Hide Mineral Resource'" />
        <div
          v-if="localTableResource?.column_mineral?.length && localTableResource?.mineral_resource?.resource?.length && !isMineralResourceTableDisable"
          class="col-12 mb-24 table--column-w-fixed">
          <div class="mb-2 d-flex align-items-center col-12">
            <label class="d-inline-block mb-0 me-3">Mineral Resource</label>
            <kbutton
              @click="calculateTable(localTableResource?.column_mineral, localTableResource?.mineral_resource?.resource)">
              Calculate</kbutton>
            <kbutton class="ms-3" @click="toggleDialog(localTableResource?.mineral_resource?.resource)">Clear</kbutton>
          </div>
          <Grid :class="'mineral-resource mineral mb-3'" ref="grid"
            :data-items="localTableResource?.mineral_resource?.resource" :edit-field="'inEdit'" @rowclick="rowClick2"
            @itemchange="itemChange" :columns="localTableResource?.column_mineral">
            <template v-for="column in localTableResource?.column_mineral" v-slot:[`${column.field}Cell`]="{ props }">
              <td>
                <div @click="props.edit" v-if="!props.dataItem.inEdit">
                  {{ props.dataItem[column.field] }}
                </div>
                <numerictextbox v-else-if="column.calculated" :step="0" :format="'##.####'" type="number"
                  v-model.trim="props.dataItem[column.field]" class="w-100" />
                <numerictextbox v-else :step="0" :format="'##.####'" type="number"
                  v-model.trim="props.dataItem[column.field]" class="w-100" />
              </td>
            </template>
          </Grid>
          <div class="d-flex gap-4">
            <div class="col-md-4 col-sm-6 col-12"
              v-for="(single,index) in localTableResource?.mineral_resource?.cut_off_grade">
              <label class="">{{single.label}}: </label>
              <numerictextbox :class="'h-38'" type="number" :placeholder="'-'" :value="single[index]"
                @input="mineralResoureCutOffGrade($event,single,index)" />
            </div>
            <div class="drop-max-w-135">
              <label class="">Mining Code: </label>
              <dropdownlist :class="'w-100'" :style="{  }" :value="model?.mineral_resource?.mining_code"
                :data-items="miningCode" :text-field="'name'" :data-item-key="'id'" :filterable="false"
                @change="handleMiningCode($event, 'mineral_resource')">
              </dropdownlist>
              <span class="error-msg position-static">{{error?.mineral_mining_code}}</span>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12 position-relative"
            v-for="(single,index) in localTableResource?.mineral_resource?.current_ownership">
            <label class="">{{single.label}}: </label>
            <k-input :class="'h-38'" :placeholder="'-'" :value="single[index]" :disabled="curr_ownership_disable"
              @input="mineralResoureCurrentOwnership($event,single,index)" />
            <span class="error-msg">{{error?.mineral_current_ownership}}</span>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <label class="">Mineral Resource Notes</label>
            <k-input :class="'h-38'" :placeholder="'-'" v-model="localTableResource.mineral_resource.mineral_note_1" />
            <k-input :class="'h-38'" :placeholder="'-'" v-model="localTableResource.mineral_resource.mineral_note_2" />
            <k-input :class="'h-38'" :placeholder="'-'" v-model="localTableResource.mineral_resource.mineral_note_3" />
          </div>
        </div>
      </div>


      <div
        v-if="defaultItemStage?.stage_name != 'Exploration'&& defaultItemMainCommodities?.alias!='kaolin' && defaultItemMainCommodities?.alias!='potash' && defaultItemMainCommodities?.alias"
        class="row">
        <AppGlobalLoader :class="'text-center'" :loaderColor="'solid'"
          v-if="!localTableResource?.column_ore?.length && !localTableResource?.ore_resource?.resource?.length" />

        <checkbox v-if="isCheckBoxVisible" :value="isOreResourceTableDisable"
          @change="onChnageisOreResourceTableDisable" :label="'Hide Ore Reserve'" />
        <div
          v-if="localTableResource?.column_ore?.length && localTableResource?.ore_resource?.resource?.length && !isOreResourceTableDisable"
          class="col-12 mb-24 table--column-w-fixed">
          <div class="col-12 mb-2 d-flex align-items-center">
            <label class="d-inline-block mb-0 me-3">Ore Reserve</label>
            <kbutton
              @click="calculateTable(localTableResource?.column_ore, localTableResource?.ore_resource?.resource)">
              Calculate</kbutton>
            <kbutton class="ms-3" @click="toggleDialog(localTableResource?.ore_resource?.resource)">Clear</kbutton>
          </div>
          <Grid :class="'ore-reserve mb-3'" ref="grid" :data-items="localTableResource?.ore_resource?.resource"
            :edit-field="'inEdit'" @rowclick="rowClick2" @itemchange="itemChange2"
            :columns="localTableResource?.column_ore">
            <template v-for="column in localTableResource?.column_ore" v-slot:[`${column.field}Cell`]="{ props }">
              <td>
                <div @click="props.edit" v-if="!props.dataItem.inEdit">
                  {{ props.dataItem[column.field] }}
                </div>
                <numerictextbox v-else-if="column.calculated" :step="0" type="number" :format="'##.####'"
                  v-model.trim="props.dataItem[column.field]" class="w-100" />
                <numerictextbox v-else :step="0" type="number" :format="'##.####'"
                  v-model.trim="props.dataItem[column.field]" class="w-100" />
              </td>
            </template>
          </Grid>
          <div class="d-flex gap-4">
            <div class="col-md-4 col-sm-6 col-12"
              v-for="(single,index) in localTableResource?.ore_resource.cut_off_grade" :key="index">
              <label class="">{{single.label}}: </label>
              <numerictextbox :class="'h-38'" type="number" :placeholder="'-'" :value="single[index]"
                @input="oreResoureCutOffGrade($event,single,index)" />
            </div>
            <div class="drop-max-w-135">
              <label class="">Mining Code: </label>
              <dropdownlist :class="'w-100'" :style="{  }" :value="model?.ore_resource?.mining_code"
                :data-items="miningCode" :text-field="'name'" :data-item-key="'id'" :filterable="false"
                @change="handleMiningCode($event, 'ore_resource')">
              </dropdownlist>
              <span class="error-msg position-static">{{error?.ore_mining_code}}</span>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12 position-relative"
            v-for="(single,index) in localTableResource?.ore_resource?.current_ownership" :key="index">
            <label class="">{{single.label}}: </label>
            <k-input :class="'h-38'" :placeholder="'-'" :value="single[index]" :disabled="curr_ownership_disable"
              @input="oreResoureCurrentOwnership($event,single,index)" />
            <span class="error-msg">{{error?.ore_current_ownership}}</span>
          </div>
          <div class="col-md-6 col-sm-6 col-12">
            <label class="">Ore Resource Notes</label>
            <k-input :class="'h-38'" :placeholder="'-'" v-model="localTableResource.ore_resource.ore_note_1" />
            <k-input :class="'h-38'" :placeholder="'-'" v-model="localTableResource.ore_resource.ore_note_2" />
            <k-input :class="'h-38'" :placeholder="'-'" v-model="localTableResource.ore_resource.ore_note_3" />
          </div>
        </div>
      </div>
    </div>

    <div class="" v-if="model.deposit_type.name == 'Multiple'">
      <div
        v-if="defaultItemStage?.stage_name != 'Exploration' && defaultItemMainCommodities?.alias!='kaolin' && defaultItemMainCommodities?.alias!='potash' && defaultItemMainCommodities?.alias"
        class="row">
        <AppGlobalLoader :class="'text-center'" :loaderColor="'solid'"
          v-if="!localTableResource?.column_mineral?.length && !localTableResource?.mineral_resource?.length" />
        <checkbox v-if="isCheckBoxVisible" :value="isMineralResourceTableDisable"
          @change="onChnageisMineralResourceTableDisable" :label="'Hide Mineral Resource'" />
        <div
          v-if="localTableResource?.column_mineral?.length && localTableResource?.mineral_resource?.resource?.length && !isMineralResourceTableDisable"
          class="col-12 mb-24 table--column-w-fixed">
          <div class="mb-2 d-flex align-items-center">
            <label class="d-inline-block mb-0 me-3">Mineral Resource</label>
            <kbutton
              @click="calculateMultipleTable(model.multiple_deposit_column_mineral, model.multiple_deposit_mineral_resource.resource)">
              Calculate</kbutton>
            <kbutton class="ms-3" @click="toggleDialog(model.multiple_deposit_mineral_resource.resource)">Clear
            </kbutton>
            <span class="error-msg position-static ms-3">{{error?.multiple_mineral_current_ownership}}</span>
          </div>
          <!-- {{model.multiple_deposit_mineral_resource.resource.length > 16}} <br>
          {{mineralMesourceGridOuterHeight > 537}} -->
          <Grid ref="mineralMesourceGrid"
            :class="model.multiple_deposit_mineral_resource.resource.length > 16 || multipleMineralTableSpand ? 'sticky-col-w mineral-resource mb-3 resource-add-remove-btn' : 'mineral-resource mb-3 resource-add-remove-btn'"
            :data-items="model.multiple_deposit_mineral_resource.resource" :edit-field="'inEdit'"
            @rowclick="rowClick($event, 'first')" @itemchange="itemChange"
            :columns="model.multiple_deposit_column_mineral">
            <template v-for="(column, index) in model.multiple_deposit_column_mineral"
              v-slot:[`${column.field}Cell`]="{ props }">
              <td :rowspan="4" v-if="props.field == 'deposit' && props.dataItem.id % 4 === 1">
                <template v-if="props.dataItem.id === multiple_table_last_deposit_row">
                  <div class="text-center fw-bold">
                    Total
                  </div>
                </template>
                <template v-else>
                  <k-input type="text" v-model="props.dataItem[column.field]" :class="''" />
                  <div class="text-center mt-2 text-box-group">
                    <k-input type="text" v-model.trim="props.dataItem.deposit_percentage"
                      @input="$event => handleOwnershipInput($event, props.dataItem, 'deposit_percentage')"
                      :class="['w-50', {'border-red': updateCreateClicked && shouldShowRedBorder(props.dataItem.deposit_percentage, curr_ownership_disable)}]"
                      :disabled="curr_ownership_disable" />
                    <span class="percentage-icon-right h-38 border-1-default border-left-0 radius-0-4-4-0">
                      <percentageIconVue />
                    </span>
                  </div>
                </template>
              </td>
              <td v-else-if="props.field != 'deposit' && props.field != 'btn'">
                <div @click="props.edit" v-if="!props.dataItem.inEdit">
                  {{ props.dataItem[column.field] }}
                </div>
                <numerictextbox v-else-if="column.calculated" :step="0" type="number" :format="'##.####'"
                  v-model.trim="props.dataItem[column.field]" class="w-100" />
                <numerictextbox v-else :step="0" type="number" :format="'##.####'"
                  v-model.trim="props.dataItem[column.field]" class="w-100" />
              </td>
              <td class="k-table-td k-grid-content-sticky cursor-pointer" :rowspan="4"
                v-else-if="column.field != 'deposit' && props.field == 'btn' && props.dataItem.id % 4 === 1">
                <template v-if="props.dataItem.id === multiple_table_last_deposit_row">
                  <kbutton :fill-mode="'outline'" :rounded="'full'" :icon="'plus'" :theme-color="'dark'"
                    class="circle-border-icon-button" @click="addMineralRows"></kbutton>
                </template>
                <template v-else>
                  <kbutton :fill-mode="'outline'" :rounded="'full'" :icon="'minus'" :theme-color="'dark'"
                    class="circle-border-icon-button" @click="removeMineralRows(props.dataItem.id)"></kbutton>
                </template>
              </td>
            </template>
          </Grid>
          <div class="d-flex gap-4">
            <div class="col-md-4 col-sm-6 col-12"
              v-for="(single,index) in model.multiple_deposit_mineral_resource.cut_off_grade">
              <label class="">{{single.label}}: </label>
              <numerictextbox :class="'h-38'" type="number" :placeholder="'-'" :value="single[index]"
                @input="mineralResoureCutOffGrade($event,single,index)" />
            </div>
            <div class="drop-max-w-135">
              <label class="">Mining Code: </label>
              <dropdownlist :class="'w-100'" :style="{  }"
                :value="model?.multiple_deposit_mineral_resource?.mining_code" :data-items="miningCode"
                :text-field="'name'" :data-item-key="'id'" :filterable="false"
                @change="handleMiningCode($event, 'multiple_deposit_mineral_resource')">
              </dropdownlist>
              <span class="error-msg position-static">{{error?.multiple_deposit_mineral_mining_code}}</span>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <label class="">Mineral Resource Notes</label>
            <k-input :class="'h-38'" :placeholder="'-'"
              v-model="model.multiple_deposit_mineral_resource.mineral_note_1" />
            <k-input :class="'h-38'" :placeholder="'-'"
              v-model="model.multiple_deposit_mineral_resource.mineral_note_2" />
            <k-input :class="'h-38'" :placeholder="'-'"
              v-model="model.multiple_deposit_mineral_resource.mineral_note_3" />
          </div>
          <!-- <div class="col-md-4 col-sm-6 col-12 position-relative"
            v-for="(single,index) in localTableResource?.mineral_resource?.current_ownership">
            <label class="">{{single.label}}: </label>
            <numerictextbox :class="'h-38'" type="number" :placeholder="'-'" :value="single[index]"
            @input="mineralResoureCurrentOwnership($event,single,index)" />
                <span class="error-msg">{{error?.mineral_current_ownership}}</span>
          </div> -->
        </div>
      </div>
      <div
        v-if="defaultItemStage?.stage_name != 'Exploration'&& defaultItemMainCommodities?.alias!='kaolin' && defaultItemMainCommodities?.alias!='potash' && defaultItemMainCommodities?.alias"
        class="row">
        <AppGlobalLoader :class="'text-center'" :loaderColor="'solid'"
          v-if="!localTableResource?.column_ore?.length && !localTableResource?.ore_resource?.resource?.length" />

        <checkbox v-if="isCheckBoxVisible" :value="isOreResourceTableDisable"
          @change="onChnageisOreResourceTableDisable" :label="'Hide Ore Reserve'" />
        <div
          v-if="localTableResource?.column_ore?.length && localTableResource?.ore_resource?.resource?.length && !isOreResourceTableDisable"
          class="col-12 mb-24 table--column-w-fixed">
          <div class="col-12 mb-2 d-flex align-items-center">
            <label class="d-inline-block mb-0 me-3">Ore Reserve</label>
            <kbutton
              @click="calculateMultipleOreTable(model.multiple_deposit_column_ore, model.multiple_deposit_ore_resource.resource)">
              Calculate</kbutton>
            <kbutton class="ms-3" @click="toggleDialog(model.multiple_deposit_ore_resource.resource)">Clear</kbutton>
            <!-- <k-input type="text" v-model="model.multiple_deposit_column_ore[0].title" /> -->
            <span class="error-msg position-static ms-3">{{error?.multiple_ore_current_ownership}}</span>
          </div>
          <Grid ref="oreContentWrapGrid"
            :class="model.multiple_deposit_ore_resource.resource.length > 12 || multipleOreTableSpand ? 'mineral-resource ore-content-wrap mb-3 resource-add-remove-btn sticky-col-w' : 'mineral-resource ore-content-wrap mb-3 resource-add-remove-btn'"
            :data-items="model.multiple_deposit_ore_resource.resource" :edit-field="'inEdit'"
            @rowclick="rowClick($event, 'second')" @itemchange="itemChange"
            :columns="model.multiple_deposit_column_ore">
            <template v-for="(column, index) in model.multiple_deposit_column_ore"
              v-slot:[`${column.field}Cell`]="{ props }">
              <td :rowspan="3" v-if="props.field == 'deposit' && props.dataItem.id % 3 === 1">
                <template v-if="props.dataItem.id === multiple_table_last_deposit_ore_row">
                  <div class="text-center fw-bold">
                    Total
                  </div>
                </template>
                <template v-else>
                  <k-input type="text" v-model="props.dataItem[column.field]" :class="''" />
                  <div class="text-center mt-2 text-box-group">
                    <k-input type="text" v-model.trim="props.dataItem.deposit_percentage"
                      @input="$event => handleOwnershipInput($event, props.dataItem, 'deposit_percentage')"
                      :class="['w-50', {'border-red': updateCreateClicked &&  shouldShowRedBorder(props.dataItem.deposit_percentage, curr_ownership_disable)}]"
                      :disabled="curr_ownership_disable" />
                    <span class="percentage-icon-right h-38 border-1-default border-left-0 radius-0-4-4-0">
                      <percentageIconVue />
                    </span>
                  </div>
                </template>
              </td>
              <td v-else-if="props.field != 'deposit' && props.field != 'btn'">
                <div @click="props.edit" v-if="!props.dataItem.inEdit">
                  {{ props.dataItem[column.field] }}
                </div>
                <numerictextbox v-else-if="column.calculated" type="number" :step="0" :format="'##.####'"
                  v-model.trim="props.dataItem[column.field]" class="w-100" />
                <numerictextbox v-else type="number" :step="0" :format="'##.####'"
                  v-model.trim="props.dataItem[column.field]" class="w-100" />
              </td>
              <td class="k-table-td k-grid-content-sticky cursor-pointer" :rowspan="3"
                v-else-if="column.field != 'deposit' && props.field == 'btn' && props.dataItem.id % 3 === 1">
                <template v-if="props.dataItem.id === multiple_table_last_deposit_ore_row">
                  <kbutton :fill-mode="'outline'" :rounded="'full'" :icon="'plus'" :theme-color="'dark'"
                    class="circle-border-icon-button" @click="addOreRows"></kbutton>
                </template>
                <template v-else>
                  <kbutton :fill-mode="'outline'" :rounded="'full'" :icon="'minus'" :theme-color="'dark'"
                    class="circle-border-icon-button" @click="removeOreRows(props.dataItem.id)"></kbutton>
                </template>
              </td>
            </template>
          </Grid>
          <div class="d-flex gap-4">
            <div class="col-md-4 col-sm-6 col-12"
              v-for="(single,index) in model.multiple_deposit_ore_resource.cut_off_grade" :key="index">
              <label class="">{{single.label}}: </label>
              <numerictextbox :class="'h-38'" type="number" :placeholder="'-'" :value="single[index]"
                @input="oreResoureCutOffGrade($event,single,index)" />
            </div>
            <div class="drop-max-w-135">
              <label class="">Mining Code: </label>
              <dropdownlist :class="'w-100'" :style="{  }" :value="model?.multiple_deposit_ore_resource?.mining_code"
                :data-items="miningCode" :text-field="'name'" :data-item-key="'id'" :filterable="false"
                @change="handleMiningCode($event, 'multiple_deposit_ore_resource')">
              </dropdownlist>
              <span class="error-msg position-static">{{error?.multiple_deposit_ore_mining_code}}</span>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12">
            <label class="">Ore Resource Notes</label>
            <k-input :class="'h-38'" :placeholder="'-'" v-model="model.multiple_deposit_ore_resource.ore_note_1" />
            <k-input :class="'h-38'" :placeholder="'-'" v-model="model.multiple_deposit_ore_resource.ore_note_2" />
            <k-input :class="'h-38'" :placeholder="'-'" v-model="model.multiple_deposit_ore_resource.ore_note_3" />
          </div>
        </div>
      </div>
    </div>

    <k-dialog v-if="visibleDialog" @close="toggleDialog">
      <p class="m-4">Are you sure you want to clear?</p>
      <dialog-actions-bar>
        <kbutton @click="toggleDialog">No</kbutton>
        <kbutton @click="handleClearTable">Yes</kbutton>
      </dialog-actions-bar>
    </k-dialog>

    <k-dialog v-if="visibleDateDialog" :title="'Please Confirm'" @close="toggleVisibleDateDialog">
      <p class="m-4">Do you want to update the 'Last Updated' field?</p>
      <dialog-actions-bar>
        <kbutton @click="handleDateNoDialog">No</kbutton>
        <kbutton @click="handleDateYesDialog">Yes</kbutton>
      </dialog-actions-bar>
    </k-dialog>

    <!-- visibleTenementsDialog -->
    <editTenementsModal v-if="isEditTenementsModalShow" :selected_tenements="model.selected_tenements"
      :projectState="defaultItemStates" @onCreateTenements="onCreateTenements"></editTenementsModal>

    <editPlacementModal v-if="isShowPlacementEditModal" :propsModelValue="model" @onSavePlacement="onSavePlacement" @toggleClosePlacement="toggleClosePlacement">
    </editPlacementModal>

  </div>
</div>