<template src="./login.html" ></template>

<script>
import { Input } from '@progress/kendo-vue-inputs';
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
export default {
  name: "login",
  components: {
    "k-input": Input,
    kbutton: Button,
    checkbox: Checkbox,
    AppGlobalLoader
  },

  data: function () {
    return {
      model: {
        email: "",
        password: "",
      },
      passIcon: false,
      isLive: false
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    errorMsg() {
      return this.$store.state.auth.errorMsg;
    },
    isLoading() {
      return this.$store.state.auth.isLoading;
    },
    isTrial() {
      return this.$store.state.auth.isTrial;
    }
  },

  created() {
    if (this.isLoggedIn) {
      this.$router.push("/companies");
    }
  },

  mounted() {
    if (window.location.href.includes("https://app.miningbull.com.au/")) {
      this.isLive = true;
    } else {
      this.isLive = false;
    }
  },

  methods: {
    goToClubDashboard() {
      this.$store.commit("SET_ERROR_MESSAGE", "");
      this.$store.commit("SET_ISLOADING_AUTH", true);
      if (window.location.pathname == '/admin') {
        this.$store.dispatch("getTokenForAdmin", this.model);
      } else {
        this.$store.dispatch("getTokenUser", this.model);
      }
    },
    goToAccPage() {
      if (this.isLive) {
        window.open(`https://miningbull.com.au/subscribe/?customToken=${this.isTrial}`, '_blank');
      } else {
        window.open(`https://mnbstage.mywebcommander.com/my-account/?customToken=${this.isTrial}`, '_blank');
      }
    },

    showPassHandler(e) {
      this.passIcon = !this.passIcon;
    }
  },
};

</script>