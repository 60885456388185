<div v-if="zoom < 8" class="maps-describ k-absolute">
  Please zoom in to view projects.
</div>
<div class="position-relative overflow-hidden">
  <div class="select-input-container">
    <div class="d-flex position-absolute dropdown-search-box">
      <dropdownlist @change="debouncedDropdownChange" :value="selectedDDdata" :style="{ width: '106px' }"
        :size="'medium'" :rounded="'medium'" :data-items="dropdownDataItem" />
      <div class="position-relative">
        <k-input v-if="isProjectSelected" :value="inputValue" @input="debounceSearch" :style="{ width: '190px' }"
          :size="'medium'" :rounded="'medium'" :fill-mode="'solid'" :placeholder="'Search Box'" />
        <input v-if="isLocationSelected" id="pac-input" class="controls pac-input-controls" type="text"
          placeholder="Search Box" :style="{ width: '190px' }" />
        <span class="cursor-pointer position-absolute input-search-icon-button">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.3828 11.5H13.2695L17.75 15.9805L15.9805 17.75L11.5 13.2695V12.3828L10.457 11.3398C9.37891 12.2227 8 12.75 6.5 12.75C3.04688 12.75 0.25 9.95312 0.25 6.5C0.25 3.04688 3.04688 0.25 6.5 0.25C9.95312 0.25 12.75 3.04688 12.75 6.5C12.75 8 12.2227 9.37891 11.3398 10.457L12.3828 11.5ZM6.5 1.5C3.73828 1.5 1.5 3.73828 1.5 6.5C1.5 9.26172 3.73828 11.5 6.5 11.5C9.26172 11.5 11.5 9.26172 11.5 6.5C11.5 3.73828 9.26172 1.5 6.5 1.5Z"
              fill="#212529" />
          </svg>
        </span>
      </div>

      <div v-if="showPopup" class="popup popup-list" ref="popupList">
        <app-loader v-if="isSearchLoading" :loaderColor="'dark'"
          class="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center k-z-50" />
        <div v-if="!isSearchLoading && searchResultPolygonOptionList?.length"
          v-for="option in searchResultPolygonOptionList" :key="option.id" @click="onSelectPolygon(option)"
          class="popup-item" :class="option.id == selectedPolygonId ? 'selected' : ''">
          <h2 class="font-14 line-h-1 font-weight-600 base-color mb-5">
            {{ option?.project_name }}
          </h2>
          <p class="font-12 line-h-1 font-weight-600 gray-color-700">
            Company:
            <span class="font-weight-400">{{ option.company_name }}</span>
          </p>
          <p class="font-12 line-h-1 font-weight-600 gray-color-700">
            Primary Commodities:
            <span v-if="option?.main_commodities?.length > 0" v-for="(mainComo,index) in option?.main_commodities"
              class="font-weight-400">
              {{ mainComo.name}}{{ index < option?.main_commodities?.length - 1 ? ', ' : '' }} </span>
                <span v-else class="font-weight-400">
                  {{option?.main_commodities?.name|| `&nbsp; - `}}
                </span>
          </p>
          <p class="font-12 line-h-1 font-weight-600 gray-color-700">
            Stage:
            <span class="font-weight-400">{{ option?.stage || "-" }}</span>
          </p>
          <p class="font-12 line-h-1 font-weight-600 gray-color-700">
            Current Ownership:
            <span class="font-weight-400">{{ option?.current_ownership || `&nbsp; - ` }}%</span>
          </p>
        </div>
        <div v-if="!isSearchLoading && !searchResultPolygonOptionList?.length" class="pt-5">
          <p class="font-14 font-weight-400 gray-color-700 text-center">
            No results
          </p>
        </div>
      </div>
    </div>
  </div>

  <app-loader v-if="loading || isLoadingModal" :loaderColor="'warning'"
    class="position-absolute loader-map d-flex justify-content-center align-items-center k-z-50" />

  <GoogleMap :api-key="apiKey" style="width: 100%; height: calc(100vh - 64px)" :center="center" :zoom="zoom"
    ref="mapRef" @ready="onMapReady" @click="outsideClick">
    <Polygon v-for="(option, index) in polygonOptions" :key="index" :paths="option.paths" :options="option"
      @click="() => onClickPolygon(option)" />
    <Marker v-if="marker" :position="marker.position" :title="marker.title" />
    
    <Marker v-if="placemarkMarkers?.length" v-for="(markerd, index) in placemarkMarkers" :key="index" :options="getMarkerOptions(markerd)" @click="onClickMarker(markerd)" />
  </GoogleMap>
  <multipleProjectListModal v-if="
        isMultipleTenementsModalShow &&
        Object.keys(selectedPolygonObj).length
      " :modalData="multipleModalData" @showProjectDetails="showProjectDetails"
    @closeDrawer="onCloseMultipleProjectListModal"></multipleProjectListModal>

  <projectDetailsModal v-if="
        isSingleTenementsModalShow &&
        (Object.keys(selectedPolygonObj)?.length||(selectedMarkerObj&&Object.keys(selectedMarkerObj)?.length))
      " :modalData="modalData" :showDrawerMap="showDrawerMap" @closeDrawer="onCloseDrawerMap"
    :isFromMultiple="isFromMultiple" @onClickBack="onClickBackSingleModal" />
</div>