import axios from "axios";
import { configuration } from "./configurationProvider";
import router from '@/router';
import store from '@/store';
export const HTTP = axios.create({ baseURL: configuration.apiBaseUrl });
HTTP.interceptors.request.use(
  async (config) => {
    let token = null;
    token = localStorage.getItem("mnb_session");

    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-XSRF-TOKEN",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  async (res) => {
    if (res.status == 401) {
      return;
    }
    store.commit("SET_IS_RELOADNEEDED", true);
    return res; 
  },
  (err) => {
    if (err.response.status == 401) {
      let payload;
      if(store.state.auth?.userInfo?.is_admin !== 1){
        payload = null
      } else {
          payload = '/admin';
      }
      if(!store.state.auth?.isRefreshing){
        store.dispatch("getTokenUsingRefreshToken");
      }
    }
    return Promise.reject(err);
  }
);

export function GET(url, queryPayload = {}) {
  return HTTP.get(`${configuration.apiBaseUrl}/${url}`, queryPayload);
}

export function POST(url, body) {
  return HTTP.post(`${configuration.apiBaseUrl}/${url}`, body);
}

export function PUT(url, body) {
  return HTTP.put(`${configuration.apiBaseUrl}/${url}`, body);
}

export function DELETE(url, queryPayload = {}) {
  return HTTP.delete(`${configuration.apiBaseUrl}/${url}`, queryPayload);
}
