<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 156.3 163.2"
    style="enable-background: new 0 0 156.3 163.2"
    xml:space="preserve"
  >
    <g>
      <path
        d="M9.3,163.2c-0.2-0.1-0.4-0.3-0.7-0.3c-8.3-2.2-11.3-11.5-5.9-18.3c0.5-0.7,1.1-1.3,1.7-2c0.5,0.4,0.8,0.6,1.2,1
		c5.3,4.5,10.5,9.1,15.7,13.6c-2,3.3-4.9,5.1-8.5,6C11.7,163.2,10.5,163.2,9.3,163.2z"
      />
      <path
        d="M124.5,40.1c-0.6,1.2-0.9,2.3-1.6,3c-4.8,5.7-9.6,11.4-14.4,17c-1.7,2-3.9,2.2-5.9,0.5c-5.5-4.6-11-9.2-16.4-13.9
		c-2.1-1.8-2.2-4-0.4-6.2c4.7-5.6,9.4-11.2,14.2-16.8c1.8-2.2,4.1-2.4,6.3-0.5c5.4,4.5,10.8,9.1,16.1,13.7
		C123.3,37.8,123.8,39,124.5,40.1z"
      />
      <path
        d="M113,61c4-4.8,8-9.5,12-14.2c0.2,0,0.3,0,0.4,0.1c9.9,9.8,18.9,20.3,25.1,32.8c3.4,6.8,5.8,13.9,5.8,21.6
		c0,2.3-0.4,4.6-0.9,6.9c-0.1,0.7-1.2,1.5-2,1.6c-0.6,0-1.6-0.9-1.8-1.6c-2.2-7.8-6.8-14.3-11.8-20.5c-7.7-9.5-16.6-17.9-25.9-25.8
		C113.7,61.6,113.3,61.3,113,61z"
      />
      <path
        d="M96.6,22c-4.3,5-8.3,9.8-12.3,14.6C81,34,78,31.5,74.8,29C65.6,21.8,56,15.1,45.3,10.3c-4.4-2-9-3.6-13.8-4
		c-1.1-0.1-2-0.4-2.2-1.7c-0.2-1.3,0.6-1.9,1.7-2.4C35.8,0.2,41-0.3,46.2,0.1c12.2,1,23.2,5.6,33.7,11.5
		C85.6,14.9,90.9,18.5,96.6,22z"
      />
      <path
        d="M50.2,91.3c4.9,4.1,9.5,8.1,14.3,12.2c-8.7,10.3-17.3,20.4-26,30.7c-4.8-4-9.5-8-14.4-12.1
		C32.8,111.8,41.5,101.6,50.2,91.3z"
      />
      <path
        d="M84.8,50.5c4.9,4.1,9.5,8.1,14.4,12.2C90.5,72.9,81.9,83,73.3,93.3c-4.8-4.1-9.5-8.1-14.3-12.2
		C67.5,70.9,76.1,60.8,84.8,50.5z"
      />
      <path
        d="M29.1,148.2c-5.7-5-11.3-9.8-17-14.7c2.3-2.7,4.5-5.4,6.8-7.9c1-1.1,2.3-1.1,3.5-0.2c4.4,3.7,8.9,7.4,13.2,11.2
		c1.2,1,1.2,2.4,0.2,3.6C33.6,142.9,31.4,145.5,29.1,148.2z"
      />
      <path
        d="M109.2,20.7c0.2-0.3,0.2-0.3,0.3-0.4c3.8-5.2,7.3-5,11.4-1c1.5,1.5,3.2,2.7,4.8,4.1c2.7,2.4,3,5.5,0.7,8.3
		c-0.6,0.7-1.2,1.4-1.8,2.1C119.4,29.4,114.3,25.1,109.2,20.7z"
      />
      <path
        d="M67,100.6c-4.8-4.1-9.5-8.1-14.3-12.2c1.3-1.5,2.4-2.9,3.7-4.4c4.8,4.1,9.5,8.1,14.3,12.2C69.5,97.7,68.3,99.1,67,100.6z"
      />
      <path
        d="M23.9,154.3c-5.7-4.9-11.2-9.7-16.9-14.6c0.9-1,1.7-2,2.6-3.2c5.7,4.9,11.3,9.8,17,14.7C25.6,152.2,24.8,153.2,23.9,154.3z
		"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "iconHammer",
};
</script>
