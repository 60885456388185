<template src="./companyCreateEditComponent.html"></template>
<style lang="scss">
@import "./companyCreateEditComponent.scss";
</style>

<script>
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import companyOverViewTable from "./companyOverViewTable/companyOverViewTable.vue";
import previousNameOptions from "./previousNameOptions/previousNameOptions.vue";
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import moment from "moment";
import {
  getCompany,
  getAsxCode,
  postAsxCodeDebounce,
  getPrimaryRic,
  deleteImage,
  getValuesFromRkd,
} from "@/services/company.service";
import AppSnackBar from "../../common/AppSnackBar/AppSnackBar.vue";
import { FORXSTATEMENT_TYPES } from "@babel/types";
import { HTTP } from "@/service";
import { configuration } from "@/configurationProvider";
import router from "@/router";
import AppGlobalLoader from "@/components/common/app-global-loader/AppGlobalLoader.vue";
import deleteCompanyOptionsModal from "./deleteCompanyOptionsModal/deleteCompanyOptionsModal.vue";
import deletePreviousCompanyModal from "./deletePreviousNameModal/deletePreviousNameModal.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { getNotifications } from "@/services/dashboard.service";

export default {
  name: "companyCreateEditComponent",
  components: {
    "k-input": Input,
    kbutton: Button,
    "k-checkbox": Checkbox,
    companyOverViewTable,
    dropdowntree: DropDownTree,
    multiselect: MultiSelect,
    datePicker: DatePicker,
    AppSnackBar,
    numerictextbox: NumericTextBox,
    AppGlobalLoader,
    deleteCompanyOptionsModal,
    dropdownlist: DropDownList,
    previousNameOptions,
    deletePreviousCompanyModal,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
  },
  data: function () {
    return {
      model: {
        code: "",
        name: "",
        sector: [],
        image_id: 0,
        image_path: "",
        image_name: "",
        image_size: null,
        cash: "",
        debt: "",
        company_overview: "",
        company_options: [],
        company_Previous_names: [],
        as_of_date: null,
        currency_type: {},
        soi_method: [],
        quoted_shares: null,
        primary_ric: null,
        unquoted_shares: null,
        shares_on_issue: null,
        cdi_ratio: null,
        fundamentals_shares: null,
        company_note: "",
        include_quoted_shares: false,
        market_map: null,
        new_quoted_shares: null,
        method: null,
        new_company_overview: null,
        new_name: null,
      },
      localCdiRatio: null,
      quoteCdi: null,
      quoteUnquoted: null,
      value: [],
      buttonName: "Create",
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      errorMessage: [],
      erroCompany_options: [
        {
          option_code: false,
          quantity: false,
          exercise_price: false,
        },
      ],
      erroCompanyName_options: [
        {
          previous_asx_code: false,
          previous_company_name: false,
          previous_company_date_of_change: false,
        },
      ],
      tempPhotoPath: "",
      apiImageUpload: "/api/v1/image-upload",
      deleteCompanyOptionsId: 0,
      deletePreviousNameId: 0,
      isAsxLoading: false,
      defaultItemCurrency: {},
      // itemAsxCodes: [],
      isErrorInAsxCode: false,
      isRicLoading: false,
      isRkdLoading: false,
      currencyItem: [
        {
          text: "AUD",
          value: "AUD",
          isSelected: true,
        },
        {
          text: "USD",
          value: "USD",
          isSelected: false,
        },
        {
          text: "CAD",
          value: "CAD",
          isSelected: false,
        },
        {
          text: "GBP",
          value: "GBP",
          isSelected: false,
        },
      ],
      selectedView: [{ id: 2, title: "Quotes", name: "quotes" }],
      viewTypes: [
        {
          id: 1,
          title: "Fundamentals",
          name: "fundamentals",
        },
        {
          id: 2,
          title: "Quotes",
          name: "quotes",
        },
      ],
      includeQuote: false,
      sectorDataNew: [],
      marketMapValue: [
        { id: 60, name: "Diversified Miners", alias: "diversified" },
      ],
      isDialogVisible: false,
      showNewQuotedShares: false,
      cash_and_debt_items: [
        {
          id: 1,
          title: "RKD",
          name: "RKD",
        },
        {
          id: 2,
          title: "Quarterly Report",
          name: "Quarterly",
        },
      ],
      notification_length: 0,
      notifications: [],
      isOverviewDialogVisible: false,
      showNewOverview: false,
      isRejectDialogVisible: false,
      // unquotedTest: null,
    };
  },
  computed: {
    loggedInUserData() {
      return this.$store.state.auth.userInfo;
    },
    sectorData() {
      return this.$store.state.companyCreateEdit.sector;
    },
    asxCodeData() {
      return this.$store.state.companyCreateEdit.asxCode;
    },
    notification() {
      return this.$store.state.companyCreateEdit.isCreateUpdateNotify;
    },
    isLoadingCreateUpdate() {
      return this.$store.state.companyCreateEdit.isLoadingCreateUpdate;
    },
    timezone() {
      return this.$store.state.newProject.timezone;
    },
  },
  mounted() {
    this.$store.dispatch("getCompanySector");
    if (this.$route.params.id) {
      this.buttonName = "Update";
      let payload = {
        uuid: this.$route.params.id,
      };
      this.$store.commit("SET_ISLOADING_CREATEUPDATE", true);
      getCompany(payload)
        .then((response) => {
          if (response?.data?.result?.company?.company_options) {
            response?.data?.result?.company?.company_options.forEach(
              (element) => {
                this.addQuantityFieldHanlder();
              }
            );
          }
          this.model = response?.data?.result?.company;
          if (response?.data?.result?.company?.new_quoted_shares) {
            if (
              this.model?.unquoted_shares > 0 &&
              this.model?.quoted_shares != this.model?.new_quoted_shares
            ) {
              this.showNewQuotedShares = true;
            }
          }
          if (response?.data?.result?.company?.new_company_overview != null) {
            if (
              response?.data?.result?.company?.new_company_overview &&
              this.model?.company_overview != this.model?.new_company_overview
            )
              this.showNewOverview = true;
          }
          if (response?.data?.result?.company?.image_path) {
            this.model.image_path = response?.data?.result?.company?.image_path;
            this.model.image_id = response?.data?.result?.company?.image_id;
            this.tempPhotoPath =
              configuration.apiBaseUrl +
              "/" +
              response?.data?.result?.company?.image_path;
            this.model.image_name = response?.data?.result?.company?.image_name;
            let size = response?.data?.result?.company?.image_size;
            if (size && size < 1024) {
              this.model.image_size = `${size.toFixed(0)} B`;
            } else if (size < 1024 * 1024) {
              this.model.image_size = `${(size / 1024).toFixed(0)} KB`;
            } else {
              this.model.image_size = `${(size / (1024 * 1024)).toFixed(0)} MB`;
            }
          }
          this.sectorDataNew = this.sectorData;
          this.marketMapValue =
            response?.data?.result?.company?.sector != null
              ? [
                ...response?.data?.result?.company?.sector,
                ...this.marketMapValue,
              ]
              : this.marketMapValue;
          this.model.soi_method =
            response?.data?.result?.company?.soi_method != null
              ? response?.data?.result?.company?.soi_method
              : this.selectedView;
          // this.selectedView = response?.data?.result?.company?.soi_method != null ? response?.data?.result?.company?.soi_method : this.selectedView;

          if (this.model?.soi_method[0]?.id == 2) {
            if (
              response?.data?.result?.company?.cdi_ratio == null ||
              response?.data?.result?.company?.cdi_ratio == 0 ||
              response?.data?.result?.company?.cdi_ratio == ""
            ) {
              this.model.shares_on_issue =
                response?.data?.result?.company?.quoted_shares +
                response?.data?.result?.company?.unquoted_shares * 1;
            } else {
              this.model.shares_on_issue =
                response?.data?.result?.company?.quoted_shares +
                response?.data?.result?.company?.unquoted_shares *
                response?.data?.result?.company?.cdi_ratio;
            }
          } else {
            if (this.model.include_quoted_shares) {
              this.model.shares_on_issue =
                response?.data?.result?.company?.unquoted_shares +
                response?.data?.result?.company?.quoted_shares;
            } else {
              this.model.shares_on_issue =
                response?.data?.result?.company?.unquoted_shares;
            }
          }
          this.model.quoted_shares =
            response?.data?.result?.company?.quoted_shares;
          this.model.unquoted_shares =
            response?.data?.result?.company?.unquoted_shares == 0
              ? null
              : response?.data?.result?.company?.unquoted_shares;
          // this.value = this.model.sector;
          this.model.include_quoted_shares =
            response?.data?.result?.company?.include_quoted_shares;
          this.localCdiRatio =
            this.model.soi_method[0].id == 1
              ? response?.data?.result?.company?.cdi_ratio
              : null;
          this.quoteCdi =
            this.model.soi_method[0].id == 2
              ? response?.data?.result?.company?.cdi_ratio
              : null;
          this.model.company_note =
            response?.data?.result?.company?.company_note;
          // this.quoteUnquoted = response?.data?.result?.company?.unquoted_shares;

          this.model.cash =
            response?.data?.result?.company?.cash != null &&
              response?.data?.result?.company?.cash / 0.000001 >= 0
              ? response?.data?.result?.company?.cash / 0.000001
              : "";
          this.model.debt =
            response?.data?.result?.company?.debt != null &&
              response?.data?.result?.company?.debt / 0.000001 >= 0
              ? response?.data?.result?.company?.debt / 0.000001
              : "";
          this.defaultItemCurrency =
            response?.data?.result?.company?.currency_type;
          setTimeout(() => {
            this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
          }, 1000);

          if (this.model.soi_method[0]?.id == 2) {
            this.quoteUnquoted =
              response?.data?.result?.company?.unquoted_shares;
          }
        })
        .catch((error) => {
          this.$store.commit("SET_IS_RELOADNEEDED", true);
          this.$store.commit("SET_ISLOADING_CREATEUPDATE", false);
        });
    } else {
      setTimeout(() => {
        this.sectorDataNew = this.sectorData;
      }, 200);
    }
  },
  methods: {
    handleApprove() {
      this.isDialogVisible = true;
    },
    toggleDialog() {
      this.isDialogVisible = !this.isDialogVisible;
    },
    handleApproveFromModal() {
      this.model.quoted_shares = this.model.new_quoted_shares;
      this.isDialogVisible = !this.isDialogVisible;

      if (
        this.model?.cdi_ratio == null ||
        this.model?.cdi_ratio == 0 ||
        this.model?.cdi_ratio == ""
      ) {
        this.model.shares_on_issue =
          this.model?.quoted_shares + this.model?.unquoted_shares * 1;
      } else {
        this.model.shares_on_issue =
          this.model?.quoted_shares +
          this.model?.unquoted_shares * this.model?.cdi_ratio;
      }
      this.showNewQuotedShares = false;
    },
    handleOverviewApprove() {
      this.isOverviewDialogVisible = true;
    },
    toggleOverviewDialog() {
      this.isOverviewDialogVisible = !this.isOverviewDialogVisible;
    },
    handleApproveOverviewFromModal() {
      this.model.company_overview = this.model.new_company_overview;
      this.toggleOverviewDialog();
      this.showNewOverview = false;
    },
    toggleOverviewRejectDialog() {
      this.isRejectDialogVisible = !this.isRejectDialogVisible;
    },
    handleRejectOverviewFromModal() {
      this.model.new_company_overview = this.model.company_overview;
      this.toggleOverviewRejectDialog();
      this.showNewOverview = false;
    },
    primaryRicChange(event) {
      const value = event.target.value.toUpperCase();
      this.model.primary_ric = value;
      const payload = {
        primary_ric: value,
      };
      if (this.model.primary_ric == "") {
        this.model.fundamentals_shares = null;
        this.model.unquoted_shares = null;
        this.model.shares_on_issue = null;
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.debouncePrimaryRic(payload);
      }, 700);
      if (this.model.primary_ric == "") {
      }
    },
    debouncePrimaryRic(payload) {
      this.isRicLoading = true;
      getPrimaryRic(payload)
        .then((response) => {
          this.isRicLoading = false;
          this.errorMessage["ric"] = "";
          if (this.localCdiRatio == 0 || this.localCdiRatio == null) {
            this.localCdiRatio = 1;
          }
          this.model.fundamentals_shares =
            response?.data?.result?.company?.fundamentals_shares;
          this.model.unquoted_shares =
            response?.data?.result?.company?.fundamentals_shares *
            this.localCdiRatio;
          this.model.shares_on_issue =
            this.model.soi_method[0].id == 2
              ? this.model.quoted_shares +
              response?.data?.result?.company?.fundamentals_shares *
              this.localCdiRatio
              : this.includeQuote
                ? this.model.quoted_shares + this.model.unquoted_shares
                : this.model.unquoted_shares;
        })
        .catch((error) => {
          this.isRicLoading = false;
          this.errorMessage["ric"] = error?.response?.data?.result?.message;
        });
    },
    handleMethod(e) {
      this.model.soi_method = [e.target.value];
      this.model.soi_method = [e.target.value];
      if (this.model.soi_method[0].name == "quotes") {
        this.model.cdi_ratio = this.quoteCdi;
        this.quoteCdi =
          this.quoteCdi == 0 || this.quoteCdi == null ? 1 : this.quoteCdi;
        this.model.shares_on_issue =
          this.model.quoted_shares + this.quoteUnquoted * this.quoteCdi;
        this.model.unquoted_shares = this.quoteUnquoted;
      } else {
        this.model.cdi_ratio = this.localCdiRatio;
        this.localCdiRatio =
          this.localCdiRatio == 0 || this.localCdiRatio == null
            ? 1
            : this.localCdiRatio;
        this.model.unquoted_shares =
          this.model.fundamentals_shares * this.localCdiRatio == 0
            ? null
            : this.model.fundamentals_shares * this.localCdiRatio;
        this.model.shares_on_issue = this.model.include_quoted_shares
          ? this.model.quoted_shares + this.model.unquoted_shares
          : this.model.unquoted_shares;
      }
    },
    handleCdiRatio(e) {
      if (this.model.soi_method[0].name == "quotes") {
        if (e.value == 0 || e.value == null || e.value == "") {
          this.model.cdi_ratio = null;
          this.quoteCdi = 1;
        } else {
          this.quoteCdi = e.value;
          this.model.cdi_ratio = e.value;
        }
        this.model.shares_on_issue =
          this.model.quoted_shares + this.model.unquoted_shares * this.quoteCdi;
        // this.model.unquoted_shares = this.quoteUnquoted;
      } else {
        if (e.value == 0 || e.value == null || e.value == "") {
          this.model.cdi_ratio = null;
          this.localCdiRatio = 1;
        } else {
          this.localCdiRatio = e.value;
          this.model.cdi_ratio = e.value;
        }
        this.model.unquoted_shares =
          this.model.fundamentals_shares * this.localCdiRatio;
        // this.model.shares_on_issue = this.model.unquoted_shares + this.model.quoted_shares;
        this.model.shares_on_issue = this.model.include_quoted_shares
          ? this.model.quoted_shares + this.model.unquoted_shares
          : this.model.unquoted_shares;
      }
    },
    handleQuoteUnquotedShareChange(e) {
      this.quoteUnquoted = e.value;
      // this.model.soi_method = this.selectedView;
      if (this.model.soi_method[0].name == "quotes") {
        if (this.quoteCdi == 0 || this.quoteCdi == null) {
          this.quoteCdi = 1;
        }
        this.model.shares_on_issue =
          this.model.quoted_shares + this.quoteUnquoted * this.quoteCdi;
      } else {
        this.model.shares_on_issue = this.model.include_quoted_shares
          ? this.model.quoted_shares + this.model.unquoted_shares
          : this.model.unquoted_shares;
      }
    },
    quoteCheck(e) {
      this.includeQuote = e.value;
      this.model.include_quoted_shares = e.value;
      if (this.model.soi_method[0].name != "quotes") {
        this.model.shares_on_issue = this.model.include_quoted_shares
          ? this.model.quoted_shares + this.model.unquoted_shares
          : this.model.unquoted_shares;
      }
    },
    goToClubDashboard() {
      this.$router.push("/home");
    },
    removeGoogleImages(data) {
      // this.evData.google[data] = "";
    },
    gotoPrev() {
      router.go(-1);
    },
    onSyncQuantitys(data, index) {
      this.model.company_options[index].option_code = data.option_code;
      this.model.company_options[index].quantity = data.quantity;
      this.model.company_options[index].exercise_price = data.exercise_price;
      this.model.company_options[index].expiry_date = data.expiry_date;
    },
    onSyncPreviousName(data, index) {
      this.model.company_Previous_names[index].previous_asx_code =
        data.previous_asx_code;
      this.model.company_Previous_names[index].previous_company_name =
        data.previous_company_name;
      this.model.company_Previous_names[index].previous_company_date_of_change =
        data.previous_company_date_of_change;
      // moment(data.previous_company_date_of_change).format("DD-MM-YYYY");
    },
    QuantityCloseHandler(index) {
      if (this.model.company_options.length) {
        if (this.$route.params.id && this.model?.company_options[index]?.id) {
          this.$store.commit(
            "SET_COMPANY_OPTIONS_DELETE_MODAL_SHOW_HIDE",
            true
          );
          this.deleteCompanyOptionsId = this.model?.company_options[index]?.id;
        } else {
          this.model.company_options.splice(index, 1);
        }
      } else {
        return;
      }
      if (this.erroCompany_options.length) {
        this.erroCompany_options.splice(index, 1);
      }
    },
    previousNameCloseHandler(index) {
      if (this.model.company_Previous_names.length) {
        if (
          this.$route.params.id &&
          this.model?.company_Previous_names[index]?.id
        ) {
          this.$store.commit(
            "SET_PREVIOUS_COMPANY_DELETE_MODAL_SHOW_HIDE",
            true
          );
          this.deletePreviousNameId =
            this.model?.company_Previous_names[index]?.id;
        } else {
          this.model.company_Previous_names.splice(index, 1);
        }
      } else {
        return;
      }
      if (this.erroCompany_options.length) {
        this.erroCompanyName_options.splice(index, 1);
      }
    },
    addQuantityFieldHanlder() {
      this.erroCompany_options.push({
        option_code: false,
        quantity: false,
        exercise_price: false,
      });
      this.model.company_options.push({
        id: 0,
        option_code: "",
        quantity: "",
        exercise_price: "",
        expiry_date: "",
      });
    },
    addPreviousFieldHanlder() {
      this.erroCompanyName_options.push({
        previous_asx_code: false,
        previous_company_name: false,
        previous_company_date_of_change: false,
      });
      this.model.company_Previous_names.push({
        id: 0,
        previous_asx_code: "",
        previous_company_name: "",
        previous_company_date_of_change: "",
      });
    },
    cancel() {
      router.go(-1);
    },
    showNotification(message, type) {
      let notification = {
        message: message,
        isNotify: true,
        type: type,
      };
      this.$store.commit(
        "SET_NOTIFICATION_CREATE_UPDATE_COMPANY",
        notification
      );
      setTimeout(() => {
        let notification = {
          message: message,
          isNotify: false,
          type: type,
        };
        this.$store.commit(
          "SET_NOTIFICATION_CREATE_UPDATE_COMPANY",
          notification
        );
      }, 4000);
    },
    isVerify() {
      let isError = false;
      if (this.model.code == "") {
        this.errorMessage["code"] = "The code field is required.";
        isError = true;
      }
      if (!this.model.code) {
        this.errorMessage["code"] = "The code field is required.";
        isError = true;
      }
      if (
        (!this.model.market_map ||
          Object.keys(this.model.market_map).length == 0) &&
        this.model?.sector?.length > 0
      ) {
        this.errorMessage["market_map"] = "Primary Sector is mandatory.";
        isError = true;
      }
      if (this.model.name == "") {
        this.errorMessage["name"] = "The name field is required.";
        isError = true;
      }

      if (this.model.cash && this.model.debt && !this.model.as_of_date) {
        this.errorMessage["as_of_date"] = "As of Date is mandatory!";
        isError = true;
      }

      return isError;
    },
    filterSectorChange(event) {
      const newData =
        event.filter.value.length >= 1
          ? this.filterSectorChangeFilter(event.filter)
          : this.sectorData.slice();
      this.sectorDataNew = newData;
    },
    filterSectorChangeFilter(filter) {
      const data = this.sectorData.slice();
      return filterBy(data, filter);
    },
    // handleCurrency(event) {
    //   this.errorMessage['name'] = '';
    //   this.errorMessage['code'] = '';
    //   // this.defaultItemCurrency = event.value;
    //   this.model.code = event?.value?.asx_code;
    //   this.model.name = event.value?.name;
    // },
    // filterChangeCurrency(event) {
    //   const newData =
    //     event.filter.value.length >= 3
    //       ? this.filterDataAsxCode(event.filter)
    //       : this.asxCodeData
    //         .slice();
    //   this.itemAsxCodes = newData;
    // },
    // filterDataAsxCode(filter) {
    //   const data = this.asxCodeData.slice();
    //   return filterBy(data, filter);
    // },

    handleCurrency(event) {
      this.defaultItemCurrency = event?.value;
      this.model.currency_type = event?.value;
      this.errorMessage["currency_type"] = "";
    },
    filterChangeCurrency(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataAsxCode(event.filter)
          : this.currencyItem.slice();
      this.currencyItem = newData;
    },
    filterDataAsxCode(filter) {
      const data = this.currencyItem.slice();
      return filterBy(data, filter);
    },

    filterChangeMarketMap(event) {
      const newData =
        event.filter.value.length >= 3
          ? this.filterDataMarketMap(event.filter)
          : this.marketMapValue.slice();
      this.marketMapValue = newData;
    },
    filterDataMarketMap(filter) {
      const data = this.marketMapValue.slice();
      return filterBy(data, filter);
    },
    handleMarketMap(event) {
      if (event.value.name == "Please select..") {
        this.model.market_map = null;
      } else {
        this.model.market_map = event.value;
      }
      this.errorMessage["market_map"] = "";
    },
    handleCashAndDebt(event) {
      this.model.method = event.value;
      if (event.value.name == "RKD") {
        this.isRkdLoading = true;
        getValuesFromRkd(this.model.code)
          .then((response) => {
            this.model.cash =
              response?.data?.result?.company?.cash &&
              response?.data?.result?.company?.cash / 0.000001;
            this.model.debt =
              response?.data?.result?.company?.debt &&
              response?.data?.result?.company?.debt / 0.000001;
            this.model.as_of_date = response?.data?.result?.company?.as_of_date;
            this.defaultItemCurrency =
              response?.data?.result?.company?.currency_type;
            this.model.currency_type =
              response?.data?.result?.company?.currency_type;
            this.isRkdLoading = false;
          })
          .catch((error) => {
            this.isRkdLoading = false;
          });
      } else {
        this.model.cash = "";
        this.model.debt = "";
        this.model.as_of_date = null;
        this.defaultItemCurrency = {};
        this.model.currency_type = {};
      }
    },
    handleNewName(e) {
      if (e.value == "") {
        this.isAsxLoading = true;
        var payload = {
          asx_code: this.model.code,
        };
        postAsxCodeDebounce(payload)
          .then((response) => {
            this.isAsxLoading = false;
            this.isErrorInAsxCode = false;
            this.model.name = response?.data?.result?.company?.name;
          })
          .catch((error) => {
            this.isAsxLoading = false;
            this.isErrorInAsxCode = true;
          });
      }
    },
    saveOrUpdate() {
      this.model.timezone = this.timezone;

      if (this.isVerify()) {
        return;
      }
      if (this.model.new_name && this.model.new_name != null) {
        this.model.name = this.model.new_name;
      }
      this.$store.commit("SET_ISLOADING_CREATEUPDATE", true);
      this.model.company_options = this.model.company_options;
      if (!this.$route.params.id) {
        this.$store.dispatch("createCompany", this.model);
      }
      if (this.$route.params.id) {
        this.$store.dispatch("updateCompany", this.model);
      }
      if (this.loggedInUserData?.role?.alias == "admin") {
        setTimeout(() => {
          this.$store.dispatch("updateNotification");
        }, 1000);
      }
    },
    onChnageAsxCode(e) {
      this.errorMessage["name"] = "";
      this.errorMessage["code"] = "";
      this.model.name = "";
      if (e.value.length >= 3) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.debounceASXcode(e);
        }, 600);
      }
    },
    debounceASXcode(e) {
      this.isAsxLoading = true;
      var payload = {
        asx_code: e.value,
      };
      postAsxCodeDebounce(payload)
        .then((response) => {
          this.isAsxLoading = false;
          this.isErrorInAsxCode = false;
          this.model.code = e.value.toUpperCase();
          this.model.name = response?.data?.result?.company?.name;
        })
        .catch((error) => {
          this.isAsxLoading = false;
          this.isErrorInAsxCode = true;
          this.errorMessage["code"] = error?.response?.data?.result?.message;
        });
    },
    sectorOnchange(e) {
      this.errorMessage["sector"] = "";
      this.model.sector = [...e.target.value];
      // this.model.sector = this.value;
      this.marketMapValue = [
        ...this.model.sector,
        { id: 60, name: "Diversified Miners", alias: "diversified" },
      ];
    },
    onDateChange(event) {
      this.errorMessage["as_of_date"] = "";
      // if (event.target.value){
      //   const timezoneOffset = moment().utcOffset();
      //   this.model.as_of_date = moment(event.target.value).add(timezoneOffset, 'minutes');
      // }
      this.model.as_of_date = event.target.value;
    },
    handleCompanyLogoUpload(e) {
      this.errorMessage["logo"] = "";
      const image = e.target.files[0];
      if (image.type != "image/jpeg" && image.type != "image/png") {
        // this.showNotification("File Type Not Supported.", "error");
        return;
      }

      if (image.name.length > 200) {
        this.errorMessage["logo"] =
          "Image name exceeds the maximum limit of characters.";
        return;
      }

      // this.imgUploading = true;

      // const size = (image.size / 1024 / 1024).toFixed(2);

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.tempPhotoPath = e.target.result;
      };

      const bodyFormData = new FormData();
      bodyFormData.append("image", image);

      if (this.$route.params.id) {
        this.apiImageUpload = `/api/v1/company/${this.model?.id}/image-upload/${this.model?.image_id ? this.model?.image_id : 0
          }`;
      }

      var pp = HTTP.post(
        configuration.apiBaseUrl + this.apiImageUpload,
        bodyFormData,
        {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
        }
      )
        .then((response) => {
          if (!this.$route.params.id) {
            this.model.image_path =
              response?.data?.result?.image?.image_thumbnail_path;
            this.model.image_id = response?.data?.result?.image?.id;
          } else {
            this.$store.commit("SET_COMPANY", response?.data?.result?.company);
            router.go(0);
          }
        })
        .catch((err) => { });
    },
    handleDeleteImage(e) {
      this.errorMessage["logo"] = "";
      deleteImage(this.model.image_id)
        .then((response) => {
          router.go(0);
        })
        .catch((error) => { });
    },
  },
};
</script>
